import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SOCIAL_MEDIA_PLATFORMS } from 'src/app/utils/social-media.constant';
import { PostService } from '../../../services/repositories/post.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-social-media-tabs',
  templateUrl: './social-media-tabs.component.html',
  styleUrls: ['./social-media-tabs.component.scss'],
})
export class SocialMediaTabsComponent implements OnInit, OnChanges {
  @Input() data!: any;
  @Input() googleEventOfferStartDate!: Date;
  @Input() googleEventOfferEndDate!: Date;
  @Input() selectedPagesSources!: any[];
  @Input() googleMediaBrowserContext: any;
  @Input() previewFilesList!: any[];
  @Input() socialMediaPagesList!: any[];
  @Input() googleBtnValue!: any;
  @Input() origin!: string;

  platforms = SOCIAL_MEDIA_PLATFORMS;
  selectedPlatformSource: string = '';
  screenHeight: number = window.innerHeight;
  screenWidth: number = window.innerWidth;

  activeSources: Set<string> = new Set();
  platformsWithStatus: any[] = [];

  constructor(private postService: PostService) {}

  ngOnInit() {
		console.log("tabs DATA", this.data)
    this.updateActiveSources();
    this.setDefaultSelectedPlatform();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedPagesSources'] || changes['data']) {
      this.updateActiveSources();
      this.setDefaultSelectedPlatform();
    }
  }

  updateActiveSources() {
    if (this.selectedPagesSources && this.selectedPagesSources.length) {
      this.activeSources = new Set(
        this.selectedPagesSources.map((page) => page.source.toLowerCase())
      );
    } else {
      this.activeSources = new Set();
    }
    this.updatePlatformsWithStatus();
  }

  updatePlatformsWithStatus() {
    this.platformsWithStatus = this.platforms.map((platform) => ({
      ...platform,
      isActive: this.activeSources.has(platform.source),
    }));
  }

  setDefaultSelectedPlatform() {
    if (!this.activeSources.has(this.selectedPlatformSource)) {
      const firstActivePlatform = this.platforms.find((platform) =>
        this.activeSources.has(platform.source)
      );
      if (firstActivePlatform) {
        this.selectedPlatformSource = firstActivePlatform.source;
      } else {
        this.selectedPlatformSource = '';
      }
    }
  }

  selectPlatform(source: string) {
    this.selectedPlatformSource = source;
  }

  getTabClasses(platform:any) {
		// 	platform.isActive && selectedPlatformSource === platform.source ? 'flex-[2]' : 'flex-1',
		// 	platform.isActive && selectedPlatformSource === platform.source ? 'bg-white' : 'bg-[#D9DBF7]',

    const isSelected = platform.isActive && this.selectedPlatformSource === platform.source;
    const classes = [];

		// border
		if (this.origin && this.origin == "show-post") {
			classes.push(isSelected ? "border-b-0" : '')
		} else {
			classes.push("border-white")
		}

    // Flex classes
    classes.push(isSelected ? 'flex-[2]' : 'flex-1');

    // Background color class
    if (isSelected && platform.bg_class) {
      classes.push('bg-white');
    }

    // Cursor style
    classes.push(platform.isActive ? 'cursor-pointer' : 'cursor-not-allowed');

    // Disabled styling
    if (!platform.isActive) {
      classes.push('bg-gray-300');
    }

    return classes;
  }

  preparePostDataForPreview(): any {
    return {
      typePost: this.data?.typePost,
      text: this.data?.text,
      title: this.data?.title,
      googleEventOfferStartDate: this.googleEventOfferStartDate,
      googleEventOfferEndDate: this.googleEventOfferEndDate,
			googleBtnValue: this.googleBtnValue,
      socialMedia: this.selectedPagesSources,
      mediaGoogle: this.googleMediaBrowserContext?.data?.selected?.url
        ? this.googleMediaBrowserContext.data.selected.url
        : this.previewFilesList[0]?.url,
      MediaList: this.previewFilesList.filter(
        (item) =>
          item?.type?.startsWith('image') || item?.mimeType?.startsWith('image')
      ),
    };
  }
}
