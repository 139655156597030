<div class="grid grid-cols-9 grid-rows-1 gap-6 h-[75vh]" >
	<div class="col-span-5 bg-white rounded-3xl pt-5 pr-[50px] h-full"  [style.width.px]="screenWidth*41/100">
		<div class="grid grid-rows-1 grid-cols-12 mb-3 relative">
			<span class="col-span-1">
				<span class="ml-[5rem] text-2xl cursor-pointer" (click)="prevDay()">←</span>        
			</span>

			<span class="col-span-10 text-gray-400 font-extrabold text-base w-42 px-5  mx-auto mt-2 ">
				<span class="ml-[5rem]">
					{{ getCurrentDayStr() + " " + currentDate.getDate() }}
					<span (click)="switchToMonthView(currentDate.getMonth())" class="cursor-pointer font-extrabold">{{getCurrentMonthStr()}} </span>
					<span (click)="switchToYearView(currentDate.getFullYear())"class="cursor-pointer font-extrabold">{{currentYear}}</span>
				</span>
			</span>

			<span class="col-span-1">
				<span class="text-2xl float-right mr-1 cursor-pointer" (click)="nextDay()">→</span>
			</span>
		</div>

		<div id="day-container" class="h-[65vh] " style=" overflow-y: auto;" [style.width.px]="screenWidth*38/100">
			<table  style="width: calc(100% - 5rem);" class=" m-auto mr-1 bg-gray-100 border border-2 border-gray-300 rounded-3xl border-separate">
				<tr *ngFor="let hour of generateRange(24); let i = index;">
					<td 
						class="relative border-gray-300 h-16" 
						[ngClass]="{
							'border-b ':i!=23
						}"
					>
						<div class="" *ngIf="getDayPostsByHour(hour)?.length!=0">       
							<app-post-day-label  (labelClicked)="showFullHourPreview($event)" [day]="currentDate.getFullYear()+'-'+(currentDate.getMonth()+1)+'-'+ currentDate.getDate()" [hour]="(hour)" [posts]="getDayPostsByHour(hour)"/>
						</div>
						<span 
							class="text-sm absolute text-gray-300" 
							style="top: 3.1rem;" 
							[style.left]="isLocalSetToEN()? '-4.1rem':'-3.1rem'" 
						>{{getHourLabel(hour)}}</span>                        
					</td>
				</tr>
			</table>
		</div>
	</div>
	
	<div class="col-span-4"  >
		<app-show-post 
			class="w-full h-full block"	
			[screenHeight]="screenHeight" [screenWidth]="screenWidth" [selectedPostId]="selectedPostId" [posts]="currentDayPosts"/>

	</div>
</div>