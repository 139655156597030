import { Component,Renderer2,ElementRef,  ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { Debug } from 'src/app/utils/debug';
import { SocialMediaAPIService } from 'src/app/services/utilities/socialMediaApi.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { StatsIcon } from 'src/app/enums/statsSVGIcon';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { BaseComponent } from 'src/app/components/base/base.component';


@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.scss']
})
export class PostDetailsComponent extends BaseComponent {
  groupsDropDown: boolean = false;
  pagesDropDown: boolean = false;
  isLoading: boolean = true; 
  selectedPlateform: string = '';
  data: any = {};
  saveData: string = '';
  writeReply: any = null;
  pages: any[] = [];
  groups: any[] = [];
  comments: any[] = [];
  environment = environment;
  textpost: string =  "Ce texte latin, généralement utilisé, proviendrait d'un texte latin écrit par Cicéron en 45 avant J.-C., sans copier le texte original au complet, il reprendrait seulement certaines parties et ajouterait des fautes. Ainsi, le mot lorem proviendrait du latin dolorem donnant la traduction de dolorem ipsum : la douleur."
  noData : boolean = false;
  all: boolean = false;
	platformFlags: { [key: string]: boolean } = {};
  linkedinPages: any[] = [];
  googlePages: any[] = [];
  facebookPages: any[] = [];
  instagramPages: any[] = [];
  linkedinInsights: any = {};
  facebookInsights: any = {};
  instagramInsights: any = {};
  linkedinData: any = null;
  googleData: any = null;
  facebookData: any = null;
  instagramData: any = [];
  allData: any = {};
	actionDropdown: boolean = false;
	user: any = null;
  screenHeight : number = 0;
  screenWidth : number = 0;
  groupScopes: number[] = [];
  nbOfLinkedindPages: number = 1;

  constructor(
		translationService: TranslationService,
    private eRef: ElementRef,
    private renderer: Renderer2,
    private authService: AuthService,
    private socialMediaAPIService: SocialMediaAPIService,
    private postService:PostService,
    private screenSizeService: ScreenSizeService
  ){
		super(translationService);
	}

  ngOnInit(){
    this.loadUser();
    this.screenSizeService.screenWidth$.subscribe((width: number) => this.screenWidth = width);
    this.screenSizeService.screenHeight$.subscribe((height: number) => this.screenHeight = height);
    this.groupScopes = this.postService.getGroupScopesFromLocalStorage()
    //fetch Posts by id from url
    const postId = document.URL.split('/')[document.URL.split('/').length-1]
    
    this.getPost(postId)
    this.postService.currentGroups.subscribe(
      (groups) => {
        // this.isLoading = true;
        this.groupScopes = groups;
        // this.reinitializeData()
        if(this.saveData != ''){
          this.reinitializeData()
          this.data = JSON.parse(this.saveData)
          this.prepareData()
        }
      }
    );
	}
  
  getPost(postId: string){ 
    this.postService.getPostById(postId).pipe(
      catchError((error: any) => {
        if (error.status === 403) {
          window.location.href = "/not-allowed";
        }
        if (error.status === 404) {
          window.location.href = "/not-exists";
        }
        if (error.status === 500) {
          window.location.href = "/error";
        }
        // Return an empty observable to terminate further processing
        return of(null);
      })
    ).subscribe((res: any) => {
      //this.data is the current 
      this.data = res.data
      console.log('this.data', this.data);
      
      this.fetchInsights();
      this.saveData = JSON.stringify(this.data)
      this.prepareData();
      this.isLoading = false;

    })
  }
  
  prepareData(){
    let list: any[] = [];
    this.data.SocialPlatforms = this.data.SocialPlatforms.filter((item: any) => {
      if(!list.includes(item.group.id)){
        this.groups.push({id: item.group.id, name: item.group.name});
        list.push(item.group.id);
      }
      return this.groupScopes.includes(item.group.id);
    });
    console.log('list',list);
    
    this.data.SocialPlatforms.forEach((item:any)=>{
      if(item.source == "linkedin"){
        this.linkedinData = item;
        if(!this.isNotEmptyObject(this.linkedinInsights) && item?.postAnalytics)
          this.linkedinInsights.postAnalytics = item.postAnalytics
        else if(item.postAnalytics){
          this.nbOfLinkedindPages += 1;
          this.sumLinkedinPostAnalytics();
        }
        this.linkedinPages.push({'name':item.pageName, "url": "https://www.linkedin.com/feed/update/"+item.externalId, "source": item.source});
        this.pages.push({'name':item.pageName, "url": "https://www.linkedin.com/feed/update/"+item.externalId, "source": item.source});
      }
      else if(item.source == "google"){
        // this.googleData = item;
        this.googlePages.push({'name':item.pageName, "url": item.additionalData?.post_url? item.additionalData?.post_url: item.url , "source": item.source});
        this.pages.push({'name':item.pageName, "url": item.url, "source": item.source});
      }
      else if(item.source == "facebook"){
        this.facebookData = item;
        if(!this.isNotEmptyObject(this.facebookInsights) && item?.postAnalytics){
          item.postAnalytics.comments = this.countFbComments(item);
          this.facebookInsights.postAnalytics = item.postAnalytics
        }
        else if(item.postAnalytics){
          item.postAnalytics.comments = this.countFbComments(item);
          this.sumFBPostAnalytics()
        }
        this.facebookPages.push({'name':item.pageName, "url": "https://www.facebook.com/"+item.externalId, "source": item.source});
        this.pages.push({'name':item.pageName, "url": "https://www.facebook.com/"+item.externalId, "source": item.source});
      }
      else if(item.source == "instagram"){
        this.instagramData = item;
        if(!this.isNotEmptyObject(this.instagramInsights) && item?.postAnalytics)
          this.instagramInsights.postAnalytics = item.postAnalytics
        else if(item.postAnalytics)
          this.suminstagramPostAnalytics()
        this.instagramPages.push({'name':item.pageName, "url": item.url, "source": item.source});
        this.pages.push({'name':item.pageName, "url": item.postUrl?item.postUrl:item.url, "source": item.source});
      }
    })
    this.allStats();
    if(this.nbOfLinkedindPages > 1){
      this.linkedinInsights.postAnalytics.clickRate = (this.linkedinInsights.postAnalytics.clickRate/this.nbOfLinkedindPages).toFixed(2)
      this.linkedinInsights.postAnalytics.engagementRate = (this.linkedinInsights.postAnalytics.engagementRate/this.nbOfLinkedindPages).toFixed(2)
    }
    
    if(this.data.SocialPlatforms.length == 0){
      this.data = {}
      this.noData = true;
    }else{
      this.noData = false;
      this.chooseSelectedPlateform();
    }

  }

  chooseSelectedPlateform(){
    const platforms = ["linkedin", "google", "facebook", "instagram"];
    // Extract unique platforms from the data
    const uniquePlatforms = new Set<string>(
      this.data.SocialPlatforms
        .map((item: { source: string }) => item.source)
        .filter((source:string) => platforms.includes(source))
    );

    // Set the platform flags based on the unique platforms
    uniquePlatforms.forEach(platform => {
      this.platformFlags[platform] = true;
    });

    // Count the number of sources
    const numberOfSources = uniquePlatforms.size;
    console.log("numberOfSources", numberOfSources);
    
    console.log("numberOfSources", numberOfSources)
    if (numberOfSources > 1) {
      this.all = true;
      this.selectedPlateform = 'all'
    }
    else{
      this.selectPlateform(this.data.SocialPlatforms[0].source)
    }
  }

	loadUser() {
		this.authService.loadCurrentUser().subscribe((res) => {
			this.user = res?.data
		})
	}
	
	fetchInsights() {
		let postId = this.data.id;
		this.data.SocialPlatforms.forEach((socialPlatform: any) => {
      const platform = socialPlatform.source.toLowerCase();
      if(this.groupScopes.includes(socialPlatform.group.id)){
        if (platform === "facebook" || platform === "linkedin" || platform === "instagram") {
          const socialMediaId = socialPlatform.id; // Define `socialMediaId` locally
          
          if (platform === "facebook") {
            this.socialMediaAPIService.fetchPostInsights(postId, socialMediaId).subscribe((res: any) => {
              socialPlatform.postAnalytics = res.data.postAnalytics
              console.log('res.data fb',res.data);
            });
          }
          if (platform === "linkedin") {
            this.socialMediaAPIService.fetchPostInsights(postId, socialMediaId).subscribe((res: any) => {
              socialPlatform.postAnalytics = res.data.postAnalytics
              console.log('linkedin data', res.data);
            });
          }
          if (platform === "instagram") {
            this.socialMediaAPIService.fetchPostInsights(postId, socialMediaId).subscribe((res: any) => {
              console.log('intagram data', res.data);
              socialPlatform.postAnalytics = res.data.postAnalytics
            });
          }
        }
      }
		});
		
	
    //load comment if linkedin is active
    if(this.selectedPlateform=="linkedin"){
      this.comments = this.linkedinData.comments
			this.selectFirstComment()
    }
	}

  getPages(){
    switch( this.selectedPlateform){
      case "linkedin":return this.linkedinPages;
      case "google":return this.googlePages;
      case "facebook":return this.facebookPages;
      case "instagram":return this.instagramPages;
    }
    return this.pages
  }

  selectPlateform(plateform: string){
    switch (plateform){
      case 'linkedin':
        this.selectedPlateform = 'linkedin';
        this.comments = this.linkedinData.comments
        break;
      case 'google':
        this.selectedPlateform = 'google';
        break;
      case 'facebook':
        this.selectedPlateform = 'facebook';
        this.comments = this.facebookData.comments
        break;
      case 'instagram':
        this.selectedPlateform = 'instagram';
        this.comments = this.instagramData.comments
         break;
      default:
        this.selectedPlateform = 'all';
    }
    //loaded post comments 
    console.log("post comments",this.comments);
    
    this.isLoading = false;
  }

  getSRCByPlatform(){
    if(this.selectedPlateform=='all')return ""
    return "assets/img/simple-logo-"+this.selectedPlateform+".png";
  }

  formatCommentTime(time:string){
    let x = new Date(time)
    return x.getFullYear() +"/"+(x.getMonth()+1).toString().padStart(2,'0') +"/"+x.getDate().toString().padStart(2,'0') +" - "+x.getHours().toString().padStart(2,'0') +":"+x.getMinutes().toString().padStart(2,'0')
  }

  SetIconClick() : StatsIcon { return StatsIcon.CLICK }
  SetIconShare() : StatsIcon { return StatsIcon.SHARE }
  SetIconChatBubble() : StatsIcon { return StatsIcon.CHAT_BUBBLE } 
  SetIconView() : StatsIcon { return StatsIcon.VIEW }
  SetIconUserFavorite() : StatsIcon { return StatsIcon.USER_FAVORITE }
  SetIconSmilyFace() : StatsIcon { return StatsIcon.SMILY_FACE }
  SetIconHeart() : StatsIcon { return StatsIcon.HEART }
  SetIconActivity() : StatsIcon { return StatsIcon.ACTIVITY }
  SetIconBookMark() : StatsIcon { return StatsIcon.BOOKMARK }
  SetIconStar() : StatsIcon { return StatsIcon.STAR }
  SetIconReactions() : StatsIcon { return StatsIcon.REACTIONS }
  SetIconFbReactions() : StatsIcon { return StatsIcon.FBREACTIONS }
  SetIconClickRate() : StatsIcon { return StatsIcon.CLICKRATE }
  SetIconEngagement() : StatsIcon { return StatsIcon.ENGAGEMENT }
  SetIconEngagementRate() : StatsIcon { return StatsIcon.ENGAGEMENTRATE }
  SetIconImpression() : StatsIcon { return StatsIcon.IMPRESSION }
  SetIconSaved() : StatsIcon { return StatsIcon.SAVED }
  SetIconInteraction() : StatsIcon { return StatsIcon.INTERACTION }
  SetIconActivityProfil() : StatsIcon { return StatsIcon.ACTIVITYPROFIL }
  SetIconNewFans() : StatsIcon { return StatsIcon.NEWFANS }
  SetIconProfilVisit() : StatsIcon { return StatsIcon.PROFILVISIT }

  dropdownpages(){
    this.pagesDropDown = !this.pagesDropDown;
      if (this.pagesDropDown) {
        this.renderer.listen('document', 'click', (event) => {
          if (!this.eRef.nativeElement.contains(event.target)) {
            this.pagesDropDown = false;
          }
        });
      }
  }

  dropDownOpen(type:string){
    if(type == "pages"){
      this.pagesDropDown = !this.pagesDropDown;
      this.groupsDropDown = false;
      if (this.pagesDropDown) {
        this.renderer.listen('document', 'click', (event) => {
          if (!this.eRef.nativeElement.contains(event.target)) {
            this.pagesDropDown = false;
          }
        });
      }
      return
    }
    if(type == "groups"){
      this.groupsDropDown = !this.groupsDropDown;
      this.pagesDropDown = false;
    }
  }
	
	selectFirstComment() {
    const firstComment = this.comments[0];
    if (firstComment) {
      this.reply(firstComment);
    }
  }

  reply(comment: any) {
    this.writeReply = comment;
    this.scrollToElement()
  }

  scrollToElement() {
    // let elementId = 'comment';
    // console.log("scrolling...");
    // setTimeout(() => {
    //   const element = document.getElementById(elementId) as HTMLTextAreaElement;
    //   const container = document.getElementById('comment-container');
    //   if (element) {
    //     element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //   }
    // }, 800);
    
    // element.focus()
  }
  message:string=""
  processComent(){
    if(this.writeReply){
      this.sendReply()
    }
    else {
      this.sendComment()
    }
  }
  sendComment(){
    console.log("sending comment");
    
    this.isLoading =true;
    let page=null
    switch( this.selectedPlateform){
      case "linkedin":
        page = this.linkedinData
      break;
    }
    const commentID =page.externalId

    this.postService.sendComment({actor:`urn:li:organization:${page.pageId}`,message:{text:this.message},urn:commentID},page).subscribe((res:any)=>{
      console.log("res",res)
      this.comments = res
      this.message = ""
      this.isLoading = false
    })
    
  }

  sendReply(){
    console.log("replying on comment");
    this.isLoading =true;
    console.log(this.writeReply);
    console.log("message",this.message);
    let page=null
    switch( this.selectedPlateform){
      case "linkedin":{

        page = this.linkedinData
        const commentID =page.externalId
        this.postService.sendReply({actor:`urn:li:organization:${page.pageId}`,message:{text:this.message},parentComment:this.writeReply.id ,urn:commentID},page).subscribe((res:any)=>{
          console.log(res)
          this.comments = res.comments
          this.linkedinData.postAnalytice = res.postAnalytics
          this.message = ""
          this.isLoading = false
        })
      }
      break;
      
      case "facebook":{
        page = this.facebookData
        const commentID =page.externalId
        console.log("---->",page)
        this.postService.sendReply({actor:`${page.pageId}`,message:{text:this.message},parentComment:this.writeReply.id ,externalId:commentID},page).subscribe((res:any)=>{
          console.log(res)
          this.comments = res.comments
          this.facebookData.postAnalytice = res.postAnalytics
          this.message = ""
          this.isLoading = false
        })
      }
      break;

      case "instagram":{
        page = this.instagramData
        const commentID =page.externalId
        this.postService.sendReply({actor:`urn:li:organization:${page.pageId}`,message:{text:this.message},parentComment:this.writeReply.id ,externalId:commentID},page).subscribe((res:any)=>{
          console.log("comments update",res)
          this.comments = res.cpmments
          console.log("new comments",this.comments)
          this.instagramData.postAnalytice = res.postAnalytics
          this.message = ""
          this.isLoading = false
        })
      }
      break;
    }   
  }
  isEmojiPickerOpen = false
  toggleImojiPicker(){
    this.isEmojiPickerOpen=!this.isEmojiPickerOpen
  }
  insertEmoji(data:any){
    this.message+=data
    this.setFocus()
  }
  @ViewChild('textarea', { static: false }) commentInput!: ElementRef;
  setFocus(): void {
    this.commentInput.nativeElement.focus();
  }
  allStats(){
    if(this.linkedinInsights?.postAnalytics || this.facebookInsights?.postAnalytics || this.instagramInsights?.postAnalytics){
      this.allData.impression = this.toNumber(this.linkedinInsights?.postAnalytics?.impressionCount) + this.toNumber(this.facebookInsights?.postAnalytics?.metrics[0].value ) +
      this.toNumber(this.renderInstaVal("impressions"))
      this.allData.reactions = this.toNumber(this.linkedinInsights?.postAnalytics?.likeCount) + this.toNumber(this.facebookInsights?.postAnalytics?.reactions) +
      this.toNumber(this.renderInstaVal("likes"))
      this.allData.interactions = this.toNumber(this.linkedinInsights?.postAnalytics?.totalEngagement) + this.toNumber(this.facebookInsights?.postAnalytics?.reactions ) + this.toNumber(this.facebookInsights?.postAnalytics?.comments ) + this.toNumber(this.facebookInsights?.postAnalytics?.shares ) + this.toNumber(this.renderInstaVal("total_interactions"))
      this.allData.shares = this.toNumber(this.linkedinInsights?.postAnalytics?.shareCount) + this.toNumber(this.facebookInsights?.postAnalytics?.shares)+
      this.toNumber(this.renderInstaVal("shares"))
      this.allData.comments = this.toNumber(this.linkedinInsights?.postAnalytics?.commentCount) + this.toNumber(this.facebookInsights?.postAnalytics?.comments)+
      this.toNumber(this.renderInstaVal("comments"))
      // this.allData.AffectedMembers = this.toNumber(this.linkedinInsights?.postAnalytics?.commentCount) + this.toNumber(this.facebookInsights?.postAnalytics?.metrics[1]?.value)+0
      //this.toNumber(this.renderInstaVal("comments"))
    }else{
      console.log(666666666);
      this.allData.impression = '-';
      this.allData.reactions = '-';
      this.allData.engagements = '-';
      this.allData.shares = '-';
      this.allData.comments = '-';
    }
  }

  gVal(data: any){
    return data && Number(data)? Number(data): '-'
  }
  
  toNumber(data: any){
    return data && Number(data)? Number(data): 0
  }

  renderVal(data: any){
   
    return data!=undefined ? data: '-'
  }
  renderInstaVal(name:string){

   const insight=this.instagramInsights?.postAnalytics?.find((insight:any)=>insight.name == name)
    return insight?insight.value:'-'
  }

	@ViewChild('actionDropdownContainer') actionDropdownContainer!: ElementRef;
	private documentClickListener: (() => void) | null = null;

	toggleActionDropdown(event: MouseEvent | null = null) {
		event?.stopPropagation(); // Prevent the click from bubbling to the document listener
    this.actionDropdown = !this.actionDropdown;

    if (this.actionDropdown) {
      this.documentClickListener = this.renderer.listen('document', 'click', (event) => {

        // Check if the click is outside the dropdown container
        if (!this.actionDropdownContainer.nativeElement.contains(event.target)) {
          this.closeActionDropdown();
        }
      });
    } else {
      this.removeDocumentClickListener();
    }
  }

	closeActionDropdown() {
    this.actionDropdown = false;
    this.removeDocumentClickListener();
  }

  private removeDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  ngOnDestroy() {
    this.removeDocumentClickListener(); // Clean up when the component is destroyed
  }

	handleAction(action: string) {
    this.actionDropdown = false; // Close dropdown after action
    switch (action) {
      case 'copy':
        console.log('Copy action triggered');
				this.useAsNewPostTemplate();
        break;
      case 'edit':
        console.log('Edit action triggered');
        break;
      case 'delete':
        console.log('Delete action triggered');
        break;
    }
  }

	useAsNewPostTemplate() {
    const postJsonStr = JSON.stringify(this.data);
    const mediaQuery = `/posts?useAs=post&postTemplate=${encodeURIComponent(postJsonStr)}`;

    Debug.log(mediaQuery);
    window.location.href = mediaQuery;
  }

  sumFBPostAnalytics(){
    this.facebookInsights.postAnalytics.shares = this.toNumber(this.facebookInsights?.postAnalytics?.shares) + this.toNumber(this.facebookData?.postAnalytics?.shares)
    this.facebookInsights.postAnalytics.comments = this.toNumber(this.facebookInsights?.postAnalytics?.comments) + this.toNumber(this.facebookData?.postAnalytics?.comments)
    this.facebookInsights.postAnalytics.reactions = this.toNumber(this.facebookInsights?.postAnalytics?.reactions) + this.toNumber(this.facebookData?.postAnalytics?.reactions)
    this.facebookInsights.postAnalytics.engagements = this.toNumber(this.facebookInsights?.postAnalytics?.engagements) + this.toNumber(this.facebookData?.postAnalytics?.engagements)
    for(let i = 0; i<3; i++){
      this.facebookInsights.postAnalytics.metrics[i].value = this.toNumber(this.facebookInsights?.postAnalytics?.metrics[i].value) + this.toNumber(this.facebookData?.postAnalytics?.metrics[i].value)
    }
  }

  sumLinkedinPostAnalytics(){
    this.linkedinInsights.postAnalytics.impressionCount = this.toNumber(this.linkedinInsights?.postAnalytics?.impressionCount) + this.toNumber(this.linkedinData?.postAnalytics?.impressionCount)
    this.linkedinInsights.postAnalytics.totalEngagement = this.toNumber(this.linkedinInsights?.postAnalytics?.totalEngagement) + this.toNumber(this.linkedinData?.postAnalytics?.totalEngagement)
    this.linkedinInsights.postAnalytics.engagementRate = this.toNumber(this.linkedinInsights?.postAnalytics?.engagementRate) + this.toNumber(this.linkedinData?.postAnalytics?.engagementRate)
    this.linkedinInsights.postAnalytics.clickCount = this.toNumber(this.linkedinInsights?.postAnalytics?.clickCount) + this.toNumber(this.linkedinData?.postAnalytics?.clickCount)
    this.linkedinInsights.postAnalytics.clickRate = this.toNumber(this.linkedinInsights?.postAnalytics?.clickRate) + this.toNumber(this.linkedinData?.postAnalytics?.clickRate)
    this.linkedinInsights.postAnalytics.likeCount = this.toNumber(this.linkedinInsights?.postAnalytics?.likeCount) + this.toNumber(this.linkedinData?.postAnalytics?.likeCount)
    this.linkedinInsights.postAnalytics.commentCount = this.toNumber(this.linkedinInsights?.postAnalytics?.commentCount) + this.toNumber(this.linkedinData?.postAnalytics?.commentCount)
    this.linkedinInsights.postAnalytics.shareCount = this.toNumber(this.linkedinInsights?.postAnalytics?.shareCount) + this.toNumber(this.linkedinData?.postAnalytics?.shareCount)
  }

  suminstagramPostAnalytics(){
    this.instagramInsights.postAnalytics.forEach((stat1 : any) => {
      const stat2 = this.instagramData.postAnalytics.find((stat: any) => stat.name === stat1.name);
      if (stat2) {
          stat1.value = this.toNumber(stat1.value) + this.toNumber(stat2.value);  // Additionner la valeur dans obj1
      }
    });
  }

	dateFormatingComments(dateString:string){
		const date = new Date(dateString);

		// Extract parts of the date
		const day = date.getDate();
		const month = date.toLocaleString('en-US', { month: 'short' });
		const year = date.getFullYear().toString().slice(-2);
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		
		// Combine into the desired format
		return `${day} ${month} ${year}, ${hours}:${minutes}`;
	}

	dateFormatingPost(dateString:string){
		const date = new Date(dateString);

		const now = new Date();
		const today = now.getDate();
		const tomorrow = today + 1;
		const yesterday = today - 1;

		// Extract parts of the date
		const day = date.getDate();
		const month = date.toLocaleString('en-US', { month: 'short' });
		const year = date.getFullYear().toString().slice(-2);
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		
		// Combine into the desired format
		// return this.translationService.t('generic.today') + ' ' + this.translationService.t('pages.at') + ` ${hours}:${minutes}`;
		// Combine into the desired format
		if (day == today) {
			return this.translationService.t('generic.today') + ' ' + this.translationService.t('pages.at') + ` ${hours}:${minutes}`;
		} else if (day == tomorrow) {
			return this.translationService.t('generic.tomorrow') + ' ' + this.translationService.t('pages.at') + ` ${hours}:${minutes}`;
		} else if (day == yesterday) {
			return this.translationService.t('generic.yesterday') + ' ' + this.translationService.t('pages.at') + ` ${hours}:${minutes}`;
		} else {
			return this.translationService.t('generic.onThe') + ` ${day} ${month} ${year}, ` + this.translationService.t('pages.at') + ` ${hours}:${minutes}`;
		}
	}

	preparePostDataForPreview(data: any): any {
		return {
			typePost: data?.typePost,
			text: data?.text,
			title: data?.title,
			googleEventOfferStartDate: null, // Not present in the input object
			googleEventOfferEndDate: null, // Not present in the input object
			googleBtnValue: null, // Not present in the input object
			socialMedia: data?.SocialPlatforms, // Extracting sources like "linkedin"
			createdAt: data?.createdAt,
			mediaGoogle: data?.SocialPlatforms?.[0]?.medias?.[0]?.url || null, // First media URL if available
			MediaList: data?.SocialPlatforms?.[0]?.medias?.filter(
				(media:any) =>
					media?.mimeType?.toLowerCase().startsWith('image') || media?.mimeType?.toLowerCase().startsWith('photo')
			) || [], // Filtering media with image-like MIME types
		};
	}

  reinitializeData(){
    this.linkedinInsights = {};
    this.facebookInsights = {};
    this.instagramInsights = {};
    this.data = {};
    this.selectedPlateform = '';
    this.writeReply = null;
    this.pages = [];
    this.comments = [];
    this.all = false;
    this.linkedinPages = [];
    this.googlePages = [];
    this.facebookPages = [];
    this.instagramPages = [];
    this.linkedinData = null;
    this.googleData = null;
    this.facebookData = null;
    this.instagramData = [];
    this.allData = {};
    this.nbOfLinkedindPages = 1;
    this.groups = [];

  }
	
  // Method to check if an object is empty
  isNotEmptyObject(obj: any): boolean {
    return obj && typeof obj === 'object' && Object.keys(obj).length !== 0;
  }

  groupsNames(): string{
    let groupsNames = '';
    if(this.groups.length > 0)
      for(let i =0; i< this.groups.length; i++){
        groupsNames +=  this.groups[i]?.name 
        groupsNames += i!=this.groups.length-1?', ':'' 
      }
    return groupsNames
  }

  addGroupsToNavBar(){
    if(this.groups.length>0){
      this.groups.forEach((item: any)=>{
        this.groupScopes.push(item.id)
      })
      this.postService.updateGroups(this.groupScopes)
      this.postService.saveGroupScopesToLocalStorage(this.groupScopes)
      // window.location.reload();
      this.postService.emitEvente('update caption'); 

    }
  }

  countFbComments(fbPost: any){
    console.log("✅",fbPost);
    
    let nbOfComments = 0;
    fbPost.comments.forEach((comment: any)=>{
      if(comment?.replies){
        nbOfComments += comment?.replies.length
        comment.replies.forEach((rr: any) => {
          if(rr?.replies)
            nbOfComments += rr?.replies.length
        });
      }
    })
    return nbOfComments + fbPost.comments.length
  }
}