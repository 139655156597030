import { ChangeDetectorRef, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { GroupService } from 'src/app/services/repositories/group.service';
import { Response } from 'src/app/interfaces/Response';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PostService } from 'src/app/services/repositories/post.service';
import { SocialMediaAPIService } from 'src/app/services/utilities/socialMediaApi.service';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-group-manager',
  templateUrl: './group-manager.component.html',
  styleUrls: ['./group-manager.component.scss'],
})
export class GroupManagerComponent {
  res: Response = {
    data: [],
    navigation: {
      page: 0,
      totalPages: 0,
      totalRows: 0,
      itemsPerPage: 0,
    },
    success: false,
    error: '',
    status: 0,
  };
  private groupsSubscription: Subscription | undefined;
  isParentSkipped: boolean = false;
  constructor(
    private groupService: GroupService,
    private postService: PostService,
    private socialMediaApiService: SocialMediaAPIService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef
  ) {}
  newGroupForm!: FormGroup;

  rootGroups: any;
  isModalOpen: boolean = false;
  modalIcon: ICON = ICON.success;

  ngOnInit() {
    this.newGroupForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      logoUrl: ['', [Validators.required]],
      clientSatisfactionId:['']
    });

    this.groupsSubscription = this.groupService.getGroups().subscribe(
      (response: Response) => {
        this.res = response; // Assign the full IResponse object

        this.res.data = this.res.data.map((g) => {
          g.Subgroups = g.children?.length;
          return g;
        });
      },
      (error) => console.error('Error fetching groups:', error)
    );

    this.groupService.getStructuredGroups().subscribe((res) => {
      this.rootGroups = res;
      // Debug.log("group tree",this.rootGroups)
      this.rootGroups.data = this.rootGroups.data.map((g: any) => {
        g.Subgroups = g.children?.length;
        return g;
      });
		console.log("rootGroups", this.rootGroups)

    });

  }

  isGroupParentValid() {
    return this.isParentSkipped || this.selectedGroup != undefined;
  }
  updateCheckbox() {
    this.isParentSkipped = !this.isParentSkipped;
    this.cdRef.detectChanges();
  }

  onUpdateGroups() {
    this.groupService
      .getDataByPage(this.res.navigation.page, this.res.navigation.itemsPerPage)
      .subscribe((res) => {
        this.res = res;
        this.res.data = res.data.map((g) => {
          g.children = g.children.length;
          return g;
        });
      });
  }

  fetchGroupTree() {
    this.groupService.getStructuredGroups().subscribe((res) => {
      this.rootGroups = res;
      // Debug.log("group tree updated",this.rootGroups)
      this.rootGroups.data = this.rootGroups.data.map((g: any) => {
        g.Subgroups = g.children?.length;
        return g;
      });
    });
  }
  currentGroup: any = {};
  facebookPostsCount: number = -1;
  linkedinPostsCount: number = -1;
  googlePostsCount: number = -1;
  instagramPostsCount: number = -1;

  showFetchPostsModal(group: any) {
    this.isFetchModalOpen = true;
    this.currentGroup = group;
    Debug.log(group.name, group.socialMedia.length);
    this.postService.getPosts(this.currentGroup.id).subscribe((res) => {
      this.facebookPostsCount =
        this.instagramPostsCount =
        this.linkedinPostsCount =
        this.googlePostsCount =
          0;

      for (let post of res.data) {
        if (post.socialMedias && post.socialMedias.length > 0) {
          for (let sm of post.socialMedias) {
            switch (sm.source.toLowerCase()) {
              case 'google':
                this.googlePostsCount++;
                break;
              case 'linkedin':
                this.linkedinPostsCount++;
                break;
              case 'facebook':
                this.facebookPostsCount++;
                break;
              case 'instagram':
                this.instagramPostsCount++;
                break;
            }
          }
        }
      }
    });

    //get posts stats
  }

  countPages(source: string): number {
    let counter = 0;
    if (
      this.currentGroup &&
      this.currentGroup.socialMedia &&
      this.currentGroup.socialMedia.length > 0
    ) {
      for (let sm of this.currentGroup?.socialMedia) {
        if (sm.source.toLowerCase() === source.toLocaleLowerCase()) counter++;
      }
    }
    return counter;
  }
  isFetchModalOpen = false;
  createNewGroup() {
    const newGroup = {
      ...this.newGroupForm.value,
      parentId:
        !this.isParentSkipped && this.selectedGroup
          ? this.selectedGroup.id
          : null,
    };

    // Debug.log(newGroup)
    this.groupService.addGroup(newGroup).subscribe();
    this.fetchGroupTree();
    //update navigation on table component
    this.updateGroupTreeForTable();

    // rest new group form
    this.newGroupForm.reset();
    this.isParentSkipped = false;
    this.selectedGroup = null;
    this.isModalOpen = true;
    setTimeout(() => {
      this.isModalOpen = false;
    }, 2000);
  }

  updateGroup(data: any) {
    // Debug.log('Update Group',data);
    this.groupService.updateGroup(data.item.id, data.item).subscribe();
    this.updateGroupTreeForTable();
  }

  deleteGroup(data: any) {
    Debug.log('Delete Group', data);
    this.groupService.deleteGroup(data.item.id).subscribe();
    this.updateGroupTreeForTable();
  }

  updateGroupTreeForTable() {
    this.groupService.getStructuredGroups().subscribe((res) => {
      this.rootGroups = res;
      // Debug.log("group tree",this.rootGroups)
      this.rootGroups.data = this.rootGroups.data.map((g: any) => {
        g.Subgroups = g.children?.length;
        return g;
      });
      Debug.log('the group root got updated to', this.rootGroups);
    });
  }

  //#region navigation
  selectedGroup: any;
  updateSelectedParentGroup(data: any) {
    this.selectedGroup = data;
  }

  getGroupsFirstPage() {}

  getGroupsNextPage() {
    Debug.log('loading next groups page');
  }

  getGroupsPreviousPage() {
    Debug.log('loading previous groups page');
  }

  getGroupsLastPage() {
    Debug.log('loading last groups page');
  }
  //#endregion

  openFetchPostsModal(group: any) {
    Debug.log('loading first groups page event  triggred on group-manager');
    Debug.log(group.socialMedia);
  }

  closeFetchPostsModal() {
    this.facebookPostsCount =
      this.googlePostsCount =
      this.linkedinPostsCount =
      this.instagramPostsCount =
        -1;
    this.isFetchModalOpen = false;
  }

  isFetchingForGooglePosts: boolean = false;
  isFetchingForInstagramPosts: boolean = false;
  isFetchingForFacebookPosts: boolean = false;
  isFetchingForLinkedInPosts: boolean = false;
  linkedInFetchData = {
    show: false,
    fetched: 0,
    added: 0,
    updated: 0,
  };
  googleFetchData = {
    show: false,
    fetched: 0,
    added: 0,
    updated: 0,
  };
  facebookFetchData = {
    show: false,
    fetched: 0,
    added: 0,
    updated: 0,
  };
  instagramFetchData = {
    show: false,
    fetched: 0,
    added: 0,
    updated: 0,
  };
  fetchPosts(source: string) {
    switch (source) {
      case 'linkedin':
        this.isFetchingForLinkedInPosts = true;
        this.linkedInFetchData.show = false;
        break;
      case 'facebook':
        this.isFetchingForFacebookPosts = true;
        break;
      case 'google':
        this.isFetchingForGooglePosts = true;
        break;
      case 'instagram':
        this.isFetchingForInstagramPosts = true;
        break;
    }

    const pagesIds = this.currentGroup.socialMedia
      .filter(
        (sm: any) => sm.source.toLowerCase() == source.toLocaleLowerCase()
      )
      .map((socialMedia: any) => socialMedia.id);

    this.socialMediaApiService.fetchPagePosts(pagesIds).subscribe((res) => {
      const syncData = res.data.data[0].fetchingResults;

      switch (source) {
        case 'linkedin':
          this.linkedInFetchData.show = true;
          this.linkedinPostsCount =
            syncData.updatedPosts + syncData.insertedPost;
          this.linkedInFetchData.fetched =
            syncData.updatedPosts + syncData.insertedPosts;
          this.linkedInFetchData.added = syncData.insertedPosts;
          this.linkedInFetchData.updated = syncData.updatedPosts;
          this.isFetchingForLinkedInPosts = false;
          break;
        case 'facebook':
          this.facebookFetchData.show = true;
          this.facebookPostsCount =
            syncData.updatedPosts + syncData.insertedPost;
          this.facebookFetchData.fetched =
            syncData.updatedPosts + syncData.insertedPosts;
          this.facebookFetchData.added = syncData.insertedPosts;
          this.facebookFetchData.updated = syncData.updatedPosts;
          this.isFetchingForFacebookPosts = false;
          break;
        case 'google':
          this.googleFetchData.show = true;
          this.googleFetchData.fetched =
            syncData.updatedPosts + syncData.insertedPosts;
          this.googleFetchData.added = syncData.insertedPosts;
          this.googleFetchData.updated = syncData.updatedPosts;
          this.isFetchingForGooglePosts = false;
          break;
        case 'instagram':
          this.instagramFetchData.show = true;
          this.instagramFetchData.fetched =
            syncData.updatedPosts + syncData.insertedPosts;
          this.instagramFetchData.added = syncData.insertedPosts;
          this.instagramFetchData.updated = syncData.updatedPosts;
          this.isFetchingForInstagramPosts = false;
          break;
      }
    });
  }

  ngOnDestroy() {
    if (this.groupsSubscription) {
      this.groupsSubscription.unsubscribe();
    }
  }
}
