// auth-redirect.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/utilities/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRedirectGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    if (localStorage.getItem('token') && this.authService.isTokenValid(localStorage.getItem('token') as string)) {
      this.router.navigate(['/home']);
      return false; // Prevents the route from being activated
    }
    return true; // Allows the route to be activated
  }
}
