<div *ngFor="let item of comments" class="overflow-auto">
	<div class="flex items-start space-x-4 mb-4">
		<!-- User Avatar  -->
		<div class="relative m-2">
			<div>
				<img *ngIf="item.isCommentByPage" width="40px" height="40px" class="rounded-full border" src="{{environment.apiUrl}}/assets/mma-assurance-icone.png" alt="">
				<svg *ngIf="platform" width="40px" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="-2.4 -2.4 28.80 28.80" stroke="#ffffff">
					<g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-2.4" y="-2.4" width="28.80" height="28.80" rx="14.4" fill="#bdbfc5" strokewidth="0"></rect></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></g>
				</svg>
			</div>
			
			<img width="20px" class="rounded-full absolute -bottom-[4px] -right-[4px] bg-white" [src]="getSRCByPlatform()" alt="">
		</div>

		<div class="w-full relative my-5">
			<div class="p-4 rounded-lg border-2" 
				(click)="reply(item)"
				[ngClass]="{
					'bg-[#1DC9B766]':item.isCommentByPage,
					'bg-[#F4F4FA]':!item.isCommentByPage,
					'border-cliking_blue':currentComment?.id == item.id,
					'border-transparent':currentComment?.id != item.id
				}"
			>
				<div class="flex justify-between pb-4">
					<span class="user-name font-bold">{{item.isCommentByPage ? linkedinPages[0]?.name : item.authorName}} </span>
					<span class="date font-light text-xs"> {{formatCommentTime(item.createdAt)}}</span>
				</div>
				<p class="">{{item.comment}}</p>
			</div>
			<div class="flex space-x-4 absolute right-0 mr-4 pt-2">
				<button class="flex items-center space-x-1 hover:bg-gray-200 rounded-lg px-1">
					<span [innerHTML]="renderLikeIconHTML()"></span>
					
					<span>{{ 'generic.like' | translate }}</span>
				</button>

				<button class="flex items-center space-x-1 hover:bg-gray-200 rounded-lg px-1" (click)="reply(item)">
					<span [innerHTML]="renderReplyIconHTML()"></span>
					<span>{{'generic.comment' | translate}}</span>
				</button>
			</div>
			<div *ngFor="let rep of item.replies" class="flex items-start space-x-4 mt-16">
				<!-- User Avatar  -->
				<div class="relative m-2">
					<div>
						<img *ngIf="rep.isCommentByPage" width="40px" height="40px" class="rounded-full border" src="{{environment.apiUrl}}/assets/mma-assurance-icone.png" alt="">
						<svg *ngIf="!rep.isCommentByPage" width="40px" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="-2.4 -2.4 28.80 28.80" stroke="#ffffff">
							<g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-2.4" y="-2.4" width="28.80" height="28.80" rx="14.4" fill="#bdbfc5" strokewidth="0"></rect></g>
							<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
							<g id="SVGRepo_iconCarrier"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></g>
						</svg>
					</div>
					<img width="20px" class="rounded-full absolute -bottom-[4px] -right-[4px] bg-white" [src]="getSRCByPlatform()" alt="">
				</div>

				<div class="w-full">
					<div class="p-4 rounded-lg border-2 cursor-pointer" 
						(click)="reply(item)"
						[ngClass]="{
							'bg-[#1DC9B766]':rep.isCommentByPage,
							'bg-[#F4F4FA]':!rep.isCommentByPage,
							'border-cliking_blue':currentComment?.id == rep.id,
							'border-transparent':currentComment?.id != rep.id
						}"
					>
						<div class="flex justify-between pb-4">
							<span class="user-name font-bold">{{rep.isCommentByPage ? linkedinPages[0]?.name : rep.authorName}} </span>
							<span class="date font-light text-xs"> {{formatCommentTime(rep.createdAt)}}</span>
						</div>
						<p>{{rep.comment}}</p>
						<div></div>
					</div>
					<div class="flex space-x-4 absolute  right-0 mr-4 pt-2">
						<button class="flex items-center space-x-1 hover:bg-gray-200 rounded-lg px-1">
							<span [innerHTML]="renderLikeIconHTML()"></span>
							<span>{{ 'generic.like' | translate }}</span>
						</button>

						<button class="flex items-center space-x-1 hover:bg-gray-200 rounded-lg px-1" (click)="reply(rep)">
							<span [innerHTML]="renderReplyIconHTML()"></span>
							<span>{{'generic.comment' | translate}}</span>
						</button>
					</div>
				</div>
				<!-- <div *ngFor="let reply of rep.replies" class="join-vertical">
					<div class="relative m-2">
						<div>
							<img *ngIf="reply.isCommentByPage" width="40px" height="40px" class="rounded-full border" src="{{environment.apiUrl}}/assets/mma-assurance-icone.png" alt="">
							<svg *ngIf="!rep.isCommentByPage" width="40px" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="-2.4 -2.4 28.80 28.80" stroke="#ffffff">
								<g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-2.4" y="-2.4" width="28.80" height="28.80" rx="14.4" fill="#bdbfc5" strokewidth="0"></rect></g>
								<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
								<g id="SVGRepo_iconCarrier"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></g>
							</svg>
						</div>
						<img width="20px" class="rounded-full absolute -bottom-[4px] -right-[4px] bg-white" [src]="getSRCByPlatform()" alt="">
					</div>
				</div> -->
			</div>
		</div>
	</div>
</div>
