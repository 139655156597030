export const SOCIAL_MEDIA_PLATFORMS = [
  {
    name: 'LinkedIn',
    color_1: 'linkedin',
    color_2: 'linkedin',
    border_class: 'border-linkedin',
    text_class: 'text-linkedin',
    bg_class: 'bg-linkedin',
    logoPath: 'assets/img/linkedin-label.png',
		simpleLogoPath: 'assets/img/simple-logo-linkedin.png',
		simpleLogoDisabledPath: 'assets/img/simple-logo-disabled-linkedin.png',
    source: 'linkedin'
  },
  {
    name: 'Google',
    color_1: 'orange-600',
    color_2: 'orange-600',
    border_class: 'border-orange-600',
    text_class: 'text-orange-600',
    bg_class: 'bg-orange-600',
    logoPath: 'assets/img/google-label.png',
		simpleLogoPath: 'assets/img/simple-logo-google.png',
		simpleLogoDisabledPath: 'assets/img/simple-logo-disabled-google.png',
    source: 'google'
  },
  {
    name: 'Facebook',
    color_1: 'facebook',
    color_2: 'facebook',
    border_class: 'border-facebook',
    text_class: 'text-facebook',
    bg_class: 'bg-facebook',
    logoPath: 'assets/img/facebook-label.png',
		simpleLogoPath: 'assets/img/simple-logo-facebook.png',
		simpleLogoDisabledPath: 'assets/img/simple-logo-disabled-facebook.png',
    source: 'facebook'
  },
  {
    name: 'Instagram',
    color_1: 'gradient-to-r from-pink-500 to-yellow-500',
    color_2: 'pink-500',
    border_class: 'border-pink-500',
    text_class: 'text-pink-500',
    bg_class: 'bg-pink-500',
    logoPath: 'assets/img/instagram-label.png',
		simpleLogoPath: 'assets/img/simple-logo-instagram.png',
		simpleLogoDisabledPath: 'assets/img/simple-logo-disabled-instagram.png',
    source: 'instagram'
  }
];

export const DEFAULT_LOGO_PATH = 'assets/img/default-logo.png';