import { Component, Input, Renderer2, ElementRef } from '@angular/core';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent {
  constructor(
    private eRef: ElementRef,
    private renderer: Renderer2,
    private screenSizeService: ScreenSizeService
  ){}
  @Input() list: any[] = [];
  @Input() type: string = '';
  screenWidth: number = 0;
  screenHeight: number = 0;
  listOpen: boolean = false;

  ngOnInit(){
    this.screenSizeService.screenWidth$.subscribe((width: number) => this.screenWidth = width);
    this.screenSizeService.screenHeight$.subscribe((height: number) => this.screenHeight = height);
  }

  listToggle(){
    this.listOpen = !this.listOpen;
    if (this.listOpen) {
      this.renderer.listen('document', 'click', (event) => {
        if (!this.eRef.nativeElement.contains(event.target)) {
          this.listOpen = false;
        }
      });
    }
  }

  calculateGapBtwPageNameAndExetrnalOpen(): number{
    if(1400 < this.screenWidth && this.screenWidth<=1500){
      return this.screenWidth*29/100
    }
    if(1300 < this.screenWidth && this.screenWidth<=1400){
      return this.screenWidth*28/100
    }
    if(1200 < this.screenWidth && this.screenWidth<=1300){
      return this.screenWidth*27/100
    }
    if(1100 < this.screenWidth && this.screenWidth<=1200){
      return this.screenWidth*26/100
    }
    if(this.screenWidth<1100){
      return this.screenWidth*25/100
    }
     return this.screenWidth*30/100
  }
}
