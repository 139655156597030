<div class="overflow-auto h-[85vh]">
    <div class="w-[95%] mb-8 mx-auto rounded-xl bg-gradient-to-r from-white via-[#E0E7FF] to-[#5D78FF] relative" >
        <img src="/assets/img/symbol-cliking.png" alt="" class="absolute right-0 h-full object-contain">
        <h1 class="text-5xl font-bold pt-5 ml-6 ">{{'generic.welcom' | translate }} <span>{{getUserFirstName()}} !</span> </h1>
        <div class="w-[50%] text-xl mt-5 ml-6">
            {{'generic.welcomMsg' | translate}}
        </div>
        <button class="bg-[#5d78ff] mt-5 mb-10 p-2 ml-6 rounded-2xl join" (click)="externLinks('/posts')">
            <span href="/posts" class="text-white mr-2">
                {{'generic.CreateAPost' | translate}}
            </span>
            <span class="my-auto">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.6001 2.1001C7.0759 2.1001 2.6001 6.5759 2.6001 12.1001C2.6001 17.6243 7.0759 22.1001 12.6001 22.1001C18.1243 22.1001 22.6001 17.6243 22.6001 12.1001C22.6001 6.5759 18.1243 2.1001 12.6001 2.1001ZM18.4065 13.2291C18.4065 13.4953 18.1888 13.713 17.9227 13.713H14.213V17.4227C14.213 17.6888 13.9953 17.9065 13.7291 17.9065H11.4711C11.2049 17.9065 10.9872 17.6888 10.9872 17.4227V13.713H7.27752C7.01139 13.713 6.79365 13.4953 6.79365 13.2291V10.9711C6.79365 10.7049 7.01139 10.4872 7.27752 10.4872H10.9872V6.77752C10.9872 6.51139 11.2049 6.29365 11.4711 6.29365H13.7291C13.9953 6.29365 14.213 6.51139 14.213 6.77752V10.4872H17.9227C18.1888 10.4872 18.4065 10.7049 18.4065 10.9711V13.2291Z" fill="white"/>
                </svg>
            </span>
        </button>
    </div>
    <table class="w-full table-auto mx-auto my-5">
        <tbody>
            <tr class="text-center">
                <td (click)="externLinks('/posts')" class="w-1/4 px-4 py-8">
                    <div class="group relative">
                        <img src="/assets/img/planifier-post.png" alt="Planifier un post"
                            class="mx-auto cursor-pointer mb-2 max-h-48 object-contain transition-all duration-300 group-hover:scale-110 opacity-80 group-hover:opacity-100">
                        <p class="text-gray-500 font-semibold cursor-pointer transition-all duration-300 group-hover:text-blue-500">
                            {{'generic.planPost' | translate }}
                        </p>
                    </div>
                </td>
                <td (click)="externLinks('/library')" class="w-1/4 px-4 py-8">
                    <div class="group relative">
                    <img src="/assets/img/publications.png" alt="Mes publications" 
                        class="mx-auto cursor-pointer mb-2 max-h-48 object-contain transition-all duration-300 group-hover:scale-110 opacity-80 group-hover:opacity-100">
                    <p class="text-gray-500 font-semibold cursor-pointer transition-all duration-300 group-hover:text-blue-500">
                        {{'generic.myPosts' | translate}}
                    </p>
                    </div>
                </td>
                <td (click)="externLinks('/dashboard')" class="w-1/4 px-4 py-8" [ngClass]="{'cursor-not-allowed':!user?.isAdmin,
                    'cursor-pointer':user?.isAdmin
                }">
                    <div class="group relative">
                    <img src="/assets/img/performance.png" [title]="!user?.isAdmin?tr('generic.comingSoon'):''"  alt="Performances" 
                        class="mx-auto  mb-2 max-h-48 object-contain" [ngClass]="{' transition-all duration-300 group-hover:scale-110 opacity-80 group-hover:opacity-100':user?.isAdmin}">
                    <p class="text-gray-500 font-semibold  transition-all duration-300 group-hover:text-blue-500">
                        {{'generic.performance' | translate}}
                    </p>
                    </div>
                </td>
                <td (click)="externLinks('/presence-management')" class="w-1/4 px-4 py-8">
                    <div class="group relative">
                    <img src="/assets/img/presence-management.png" alt="Présence Management" 
                        class="mx-auto cursor-pointer mb-2 max-h-48 object-contain transition-all duration-300 group-hover:scale-110 opacity-80 group-hover:opacity-100">
                    <p class="text-gray-500 font-semibold cursor-pointer transition-all duration-300 group-hover:text-blue-500">
                        {{'header.presenceManagement' | translate}}
                    </p>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- <div class="flex space-x-4 w-[95%] mx-auto "> -->
        
        <!-- Section Dernier post publié -->
        <!-- <div class="bg-white rounded-xl p-6 shadow-lg w-2/3">
            <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-semibold text-gray-700 flex items-center">
                    <span class="text-green-500 mr-2">✔️</span> Dernier post publié
                </h2>
                <a href="#" class="text-blue-500 text-sm flex items-center">
                    <span class="mr-1">📅</span> Voir l’analyse complète de ce post
                </a>
            </div>
            <div class="grid grid-cols-3 gap-2">
                <div class="col-span-2 bg-gray-100 p-4 rounded-lg mb-4">
                    <div class="flex items-start">
                        <img src="https://placehold.co/10x10/blue/yellow.png" alt="Icone Groupe" class="w-8 h-8 mr-2">
                        <div>
                            <h3 class="font-bold text-gray-800">Sur Groupe X</h3>
                            <p class="text-gray-600 text-sm">Nom du post</p>
                            <p class="text-gray-600 text-sm">Description Lorem ipsum dolor sit amet consectetur. Urna…</p>
                        </div>
                    </div>
                    <div class="mt-4 rounded-lg">
                        <img class="rounded-lg" src="https://placehold.co/1000x500/blue/yellow.png" alt="">
                    </div>
                    <p class="text-gray-500 text-xs mt-2">Publié hier à 18:15 sur <span class="inline-flex space-x-2">🌐📘📸</span></p>
                </div>
                <div class="grid grid-rows-3 gap-4 text-center mb-4">
                    <app-home-widget/>
                    <div class="bg-gray-100 p-4 rounded-lg">
                        <p class="text-gray-700 font-semibold">Interactions</p>
                        <p class="mb-2 text-sm join">
                            <span class="text-2xl font-bold">1,156 M</span>
                            <span class="join my-auto mx-2">
                                <span>
                                    +15.03% 
                                </span>
                                <span class="my-auto mx-1">
                                    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.45488 1.67955L13 0.0717773L11.6198 5.67788L9.89804 4.02498L7.12069 6.91804C7.02641 7.01626 6.89615 7.07178 6.76 7.07178C6.62385 7.07178 6.49359 7.01626 6.39931 6.91804L4.36 4.79377L1.36069 7.91804C1.16946 8.11725 0.85294 8.12371 0.653735 7.93247C0.454529 7.74123 0.44807 7.42472 0.639307 7.22551L3.99931 3.72551C4.09359 3.6273 4.22385 3.57178 4.36 3.57178C4.49615 3.57178 4.62641 3.6273 4.72069 3.72551L6.76 5.84979L9.17665 3.33245L7.45488 1.67955Z" fill="#212529"/>
                                    </svg>
                                </span>
                            </span>
                        </p>
                    </div>
                    <div class="bg-gray-100 p-4 rounded-lg">
                        <p class="text-gray-700 font-semibold">Réactions</p>
                        <p class="mb-2 text-sm join">
                            <span class="text-2xl font-bold">1,156 M</span>
                            <span class="join my-auto mx-2">
                                <span>
                                    +15.03% 
                                </span>
                                <span class="my-auto mx-1">
                                    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.45488 1.67955L13 0.0717773L11.6198 5.67788L9.89804 4.02498L7.12069 6.91804C7.02641 7.01626 6.89615 7.07178 6.76 7.07178C6.62385 7.07178 6.49359 7.01626 6.39931 6.91804L4.36 4.79377L1.36069 7.91804C1.16946 8.11725 0.85294 8.12371 0.653735 7.93247C0.454529 7.74123 0.44807 7.42472 0.639307 7.22551L3.99931 3.72551C4.09359 3.6273 4.22385 3.57178 4.36 3.57178C4.49615 3.57178 4.62641 3.6273 4.72069 3.72551L6.76 5.84979L9.17665 3.33245L7.45488 1.67955Z" fill="#212529"/>
                                    </svg>
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-3 gap-4 text-center">
                <div class="bg-gray-100 p-4 rounded-lg">
                    <p class="text-gray-700 font-semibold">Avis</p>
                    <p class="mb-2 text-sm join">
                        <span class="text-2xl font-bold">1,156 M</span>
                        <span class="join my-auto mx-2">
                            <span>
                                +15.03% 
                            </span>
                            <span class="my-auto mx-1">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.45488 1.67955L13 0.0717773L11.6198 5.67788L9.89804 4.02498L7.12069 6.91804C7.02641 7.01626 6.89615 7.07178 6.76 7.07178C6.62385 7.07178 6.49359 7.01626 6.39931 6.91804L4.36 4.79377L1.36069 7.91804C1.16946 8.11725 0.85294 8.12371 0.653735 7.93247C0.454529 7.74123 0.44807 7.42472 0.639307 7.22551L3.99931 3.72551C4.09359 3.6273 4.22385 3.57178 4.36 3.57178C4.49615 3.57178 4.62641 3.6273 4.72069 3.72551L6.76 5.84979L9.17665 3.33245L7.45488 1.67955Z" fill="#212529"/>
                                    </svg>
                            </span>
                        </span>
                    </p>
                </div>
                <div class="bg-gray-100 p-4 rounded-lg">
                    <p class="text-gray-700 font-semibold">Avis</p>
                    <p class="mb-2 text-sm join">
                        <span class="text-2xl font-bold">1,156 M</span>
                        <span class="join my-auto mx-2">
                            <span>
                                +15.03% 
                            </span>
                            <span class="my-auto mx-1">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.45488 1.67955L13 0.0717773L11.6198 5.67788L9.89804 4.02498L7.12069 6.91804C7.02641 7.01626 6.89615 7.07178 6.76 7.07178C6.62385 7.07178 6.49359 7.01626 6.39931 6.91804L4.36 4.79377L1.36069 7.91804C1.16946 8.11725 0.85294 8.12371 0.653735 7.93247C0.454529 7.74123 0.44807 7.42472 0.639307 7.22551L3.99931 3.72551C4.09359 3.6273 4.22385 3.57178 4.36 3.57178C4.49615 3.57178 4.62641 3.6273 4.72069 3.72551L6.76 5.84979L9.17665 3.33245L7.45488 1.67955Z" fill="#212529"/>
                                    </svg>
                            </span>
                        </span>
                    </p>
                </div>
                <div class="bg-gray-100 p-4 rounded-lg">
                    <p class="text-gray-700 font-semibold">Partages</p>
                    <p class="mb-2 text-sm join">
                        <span class="text-2xl font-bold">1,156 M</span>
                        <span class="join my-auto mx-2">
                            <span>
                                +15.03% 
                            </span>
                            <span class="my-auto mx-1">
                                <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.45488 1.67955L13 0.0717773L11.6198 5.67788L9.89804 4.02498L7.12069 6.91804C7.02641 7.01626 6.89615 7.07178 6.76 7.07178C6.62385 7.07178 6.49359 7.01626 6.39931 6.91804L4.36 4.79377L1.36069 7.91804C1.16946 8.11725 0.85294 8.12371 0.653735 7.93247C0.454529 7.74123 0.44807 7.42472 0.639307 7.22551L3.99931 3.72551C4.09359 3.6273 4.22385 3.57178 4.36 3.57178C4.49615 3.57178 4.62641 3.6273 4.72069 3.72551L6.76 5.84979L9.17665 3.33245L7.45488 1.67955Z" fill="#212529"/>
                                    </svg>
                            </span>
                        </span>
                    </p>
                </div>
            </div>
        </div> -->

        <!-- Section Posts à venir -->
        <!-- <div class="bg-white rounded-xl p-6 shadow-lg w-1/3">
            <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-semibold text-gray-700 flex items-center">
                    <span class="text-yellow-500 mr-2">🕒</span> Posts à venir
                </h2>
            </div>
            <div class="mb-6">
                <p class="font-semibold text-gray-700">Lundi 21 octobre</p>
                <div class="mt-2 space-y-4">
                    <div class="bg-gray-100 p-4 rounded-lg flex items-center">
                        <span class="text-yellow-500 text-sm mr-2">🕒 12:15</span>
                        <div class="flex items-start">
                            <img src="/path/to/logo.png" alt="Logo" class="w-16 h-16 mr-2">
                            <div>
                                <h3 class="font-bold text-gray-800">Sur Groupe X</h3>
                                <p class="text-sm text-gray-600">Nom du post</p>
                                <p class="text-xs text-gray-500">Posté dans 8 jours sur 🌐📘📸</p>
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-100 p-4 rounded-lg flex items-center">
                        <span class="text-yellow-500 text-sm mr-2">🕒 15:15</span>
                        <div class="flex items-start">
                            <img src="/path/to/logo.png" alt="Logo" class="w-16 h-16 mr-2">
                            <div>
                                <h3 class="font-bold text-gray-800">Sur Groupe X</h3>
                                <p class="text-sm text-gray-600">Nom du post</p>
                                <p class="text-xs text-gray-500">Posté dans 8 jours sur 🌐📘📸</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-6">
                <p class="font-semibold text-gray-700">Mardi 21 octobre</p>
                <div class="mt-2">
                    <div class="bg-gray-100 p-4 rounded-lg flex items-center">
                        <span class="text-yellow-500 text-sm mr-2">🕒 12:15</span>
                        <div class="flex items-start">
                            <img src="/path/to/logo.png" alt="Logo" class="w-16 h-16 mr-2">
                            <div>
                                <h3 class="font-bold text-gray-800">Sur Groupe X</h3>
                                <p class="text-sm text-gray-600">Nom du post</p>
                                <p class="text-xs text-gray-500">Posté dans 8 jours sur 🌐📘📸</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-6">
                <a href="#" class="text-blue-500 font-semibold">Voir dans le Calendrier</a>
            </div>
        </div> -->
    <!-- </div> -->

</div>

