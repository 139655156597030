import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CalenderBaseComponent } from '../../base/calendar.component';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { Debug } from 'src/app/utils/debug';
import { AuthService } from 'src/app/services/utilities/auth.service';

@Component({
  selector: 'app-show-post',
  templateUrl: './show-post.component.html',
  styleUrls: ['./show-post.component.scss'],
})
export class ShowPostComponent extends CalenderBaseComponent {
  @Input() posts: any;
  @Input() hour: number = 0;
  @Input() day: string = '';
  @Output() labelClicked = new EventEmitter();
  @Input() selectedPostId: string = '';
  @Input() screenWidth: number = 0;
  @Input() screenHeight: number = 0;
  currentIndex: number = 0;
  user!: any;

  constructor(
    protected override uiStateService: UiStateService,
    protected override postService: PostService,
    protected override translationService: TranslationService,
    protected override calendarService: CalendarService,
    protected authService: AuthService
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }

  override ngOnInit() {
    this.authService.loadCurrentUser().subscribe((user: any) => {
      this.user = user.data;
    });
  }

  // Implement ngOnChanges
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedPostId']) {
      this.onSelectedPostIdChange();
			
    }
  }

  onSelectedPostIdChange() {
    //if a selected post id change we need to update the currentIndex accordingly
    if (this.selectedPostId != '') {
      const postId = parseInt(this.selectedPostId);
      Debug.log('getting selected post index in data');

      for (let i = 0; i < this.posts.length; i++) {
        {
          if (this.posts[i].id === postId) {
            this.currentIndex = i;
          }
        }
      }
    }
  }

  getCurrentDayStr(dateStr: string) {
    let date = new Date(dateStr);
    return this.getTranslation('generic.weekDay' + date.getDay());
  }

  getpostDateAndTimeStr() {
    if (this.posts == undefined || this.posts.length == 0) return '';
    const dateStr =
      this.posts[this.currentIndex].publishingDatetime != null
        ? this.posts[this.currentIndex].publishingDatetime
        : this.posts[this.currentIndex].expectedPublishingDatetime;

    const day = this.getCurrentDayStr(dateStr);
    const month = this.getCurrentMonthStr(dateStr);

    return (
      day +
      ' ' +
      new Date(dateStr).getDate() +
      ' ' +
      month +
      ' ' +
      new Date(dateStr).getFullYear() +
      ' ' +
      this.getTranslation('generic.at') +
      ' ' +
      new Date(dateStr).getHours() +
      ':' +
      new Date(dateStr).getMinutes()
    );
  }

  prevPost() {
    this.currentIndex--;
    Debug.log(this.currentIndex);
    if (this.currentIndex < 0) {
      this.currentIndex = this.posts.length - 1;
    }
  }

  nextPost() {
    this.currentIndex++;
    Debug.log('this.posts[this.currentIndex]', this.posts[this.currentIndex]);
    if (this.currentIndex >= this.posts.length) {
      this.currentIndex = 0;
    }
  }

  currentPlatformIndex: number = 0;
  getPostSocialMedias(): any[] {
    let data = [];
    // Debug.log(this.posts[this.currentIndex])
    data = this.posts[this.currentIndex]?.SocialPlatforms;
    return data ? data : [];
  }
  nextSocialMedia() {
    // Increment the index
    this.currentPlatformIndex += 1;

    // Correct bounds checking against the length of the social media array for the current post
    if (this.currentPlatformIndex >= this.getPostSocialMedias().length)
      this.currentPlatformIndex = 0;
  }

  prevSocialMedia() {
    // Decrement the index
    this.currentPlatformIndex -= 1;

    // Correct bounds checking against the length of the social media array for the current post
    if (this.currentPlatformIndex < 0)
      this.currentPlatformIndex = this.getPostSocialMedias().length - 1;
  }

  getCurrentMonthStr(dateStr: string) {
    let date = new Date(dateStr);
    return this.getTranslation('generic.month' + (date.getMonth() + 1));
  }

  post() {
    return this.posts[this.currentIndex];
  }

  previewFilesLoad(): string[] {
    if (this.posts == undefined || this.posts.length == 0) return [];

    return this.posts[this.currentIndex].SocialPlatforms[
      this.currentPlatformIndex
    ].medias.map((image: any) => image.url);
  }

  SendPostForUpdate() {
    Debug.log('updating post', this.posts[this.currentIndex]);

    const postJsonStr = JSON.stringify(this.posts[this.currentIndex]);

    const mediaQuery = `/posts?postTemplate=${encodeURIComponent(postJsonStr)}`;

    Debug.log(mediaQuery);
    window.location.href = mediaQuery;
  }

  copyPost() {
    Debug.log('updating post', this.posts[this.currentIndex]);

    const postJsonStr = JSON.stringify(this.posts[this.currentIndex]);

    const mediaQuery = `/posts?useAs=post&postTemplate=${encodeURIComponent(postJsonStr)}`;

    Debug.log(mediaQuery);
    window.location.href = mediaQuery;
  }

	// [googleEventOfferStartDate]="googleEventOfferStartDate"
	// [googleEventOfferEndDate]="googleEventOfferEndDate"
	// [googleMediaBrowserContext]="googleMediaBrowserContext"
	// [previewFilesList]="previewFilesList"
	// [socialMediaPagesList]="socialMediaPagesList"
	// [googleBtnValue]="dynamicAdditionalData" 
	postData:any;
	selectedPagesSources:any;
	socialMediaPagesList:any;
	googleEventOfferStartDate:any;
	googleEventOfferEndDate:any;
	googleMediaBrowserContext:any;
	previewFilesList:any;
	googleBtnValue:any;
	preparePostDataForPreview() {
		const post = this.post()
		this.postData = post;
		this.selectedPagesSources = post?.SocialPlatforms;
		this.socialMediaPagesList = post?.SocialPlatforms;
		this.googleEventOfferStartDate = null;
		this.googleEventOfferEndDate = null;
		this.googleMediaBrowserContext = null;
		this.previewFilesList = post?.SocialPlatforms?.[0]?.medias?.filter(
			(media:any) =>
				media?.mimeType?.startsWith('image') || media?.mimeType?.startsWith('photo')
		) || []; // Filtering media with image-like MIME types
		this.googleBtnValue = null;

		return post
		// return {
		// 	typePost: data.typePost,
		// 	text: data.text,
		// 	title: data.title,
		// 	googleEventOfferStartDate: null, // Not present in the input object
		// 	googleEventOfferEndDate: null, // Not present in the input object
		// 	googleBtnValue: null, // Not present in the input object
		// 	socialMedia: data.SocialPlatforms, // Extracting sources like "linkedin"
		// 	createdAt: data.createdAt,
		// 	mediaGoogle: data.SocialPlatforms?.[0]?.medias?.[0]?.url || null, // First media URL if available
		// 	MediaList: data.SocialPlatforms?.[0]?.medias?.filter(
		// 		(media:any) =>
		// 			media?.mimeType?.startsWith('image') || media?.mimeType?.startsWith('photo')
		// 	) || [], // Filtering media with image-like MIME types
		// };
	}
}
