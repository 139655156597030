<div *ngIf="linkedinMedias.length==1" class="flex bg-black w-[95%] mx-auto rounded-2xl">
    <img class="" src="{{linkedinMedias[0].url}}" alt="Post Image">
</div>
<div *ngIf="linkedinMedias.length == 2">
	<div [style.height]="imgContainerHeight()" class="flex mx-auto">
		<img class="h-[100%] object-cover object-center" [ngStyle]="{'width': linkedinMedias[0].width + '%'}" [src]="linkedinMedias[0].url" alt="Image 1">
		<img class="h-[100%] object-cover object-center" [ngStyle]="{'width': (100 - linkedinMedias[0].width) + '%'}" [src]="linkedinMedias[1].url" alt="Image 2">
		<!-- <div class="flex items-center justify-center">
			<img
				class="max-w-full max-h-full object-contain"
				[src]="linkedinMedias[0].url"
				alt="Image 1"
			/>
		</div>
		<div class="flex items-center justify-center">
			<img
				class="max-w-full max-h-full object-contain"
				[src]="linkedinMedias[1].url"
				alt="Image 2"
			/>
		</div> -->
	</div> 
</div>
  
<div *ngIf="linkedinMedias.length==3">
    <div [style.height]="imgContainerHeight()" *ngIf="orientation == 'landscape'" class="grid grid-rows-3 gap-0 w-[95%] mx-auto">
        <div class="row-span-2 relative w-full h-full">
            <img class="absolute inset-0 w-full h-full object-cover object-center rounded-t-2xl " src="{{linkedinMedias[0].url}}">
        </div>
        <div class="grid grid-cols-2 gap-0 row-span-1 w-full h-full">
            <div class="relative w-full h-full">
                <img class="absolute inset-0 w-full h-full object-cover object-center rounded-bl-2xl" src="{{linkedinMedias[1].url}}">
            </div>
            <div class="relative w-full h-full">
                <img class="absolute inset-0 w-full h-full object-cover object-center rounded-br-2xl" src="{{linkedinMedias[2].url}}">
            </div>
        </div>
    </div>
    <div [style.height]="imgContainerHeight()" *ngIf="orientation=='portrait'" class="grid grid-cols-3 gap-0 w-[95%] mx-auto">
        <div  [style.height]="imgContainerHeight()" class="col-span-2">
            <img class="w-full h-full object-cover object-center rounded-l-2xl" src="{{linkedinMedias[0].url}}">
        </div>  
        <div  [style.height]="imgContainerHeight()" class="gird grid-cols-3 col-span-1 gap-0">
            <img class="w-full h-[50%] object-cover object-center rounded-tr-2xl" src="{{linkedinMedias[1].url}}">
            <img class="w-full h-[50%] object-cover object-center rounded-br-2xl" src="{{linkedinMedias[2].url}}">
        </div>
    </div>
</div>
<div *ngIf="linkedinMedias.length>3">
    <div [style.height]="imgContainerHeight()" *ngIf="orientation == 'landscape'" class="grid grid-rows-[65%_35%] gap-0 w-[95%] mx-auto">
        <div class="row-span-1 relative w-full h-full">
            <img class="absolute inset-0 w-full h-full rounded-t-2xl object-cover object-center" src="{{linkedinMedias[0].url}}">
        </div>
        <div class="grid grid-cols-3 gap-0 row-span-1 w-full h-full">
            <div class="relative w-full h-full">
                <img class="absolute inset-0 w-full h-full rounded-bl-2xl object-cover object-center" src="{{linkedinMedias[1].url}}">
            </div>
            <div class="relative w-full h-full">
                <img class="absolute inset-0 w-full h-full object-cover object-center" src="{{linkedinMedias[2].url}}">
            </div>
            <div class="relative w-full h-full">
                <img *ngIf="linkedinMedias.length == 4" class="absolute inset-0 w-full rounded-br-2xl h-full object-cover object-center" src="{{linkedinMedias[3].url}}">
                <div *ngIf="linkedinMedias.length > 4" class="relative w-full h-full">
                    <img class="absolute inset-0 w-full h-full object-cover object-center rounded-br-2xl" src="{{linkedinMedias[3].url}}">
                    <div class="absolute inset-0 bg-black bg-opacity-60 flex justify-center items-center rounded-br-2xl">
                        <span class="text-white text-2xl">+{{linkedinMedias.length - 3}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [style.height]="imgContainerHeight()" *ngIf="orientation=='portrait'" class="grid grid-cols-3 gap-0 w-[95%] mx-auto">
        <div [style.height]="imgContainerHeight()" class="col-span-2">
            <img class="w-full h-full object-cover  rounded-l-2xl object-center" src="{{linkedinMedias[0].url}}">
        </div>  
        <div [style.height]="imgContainerHeight()" class="gird grid-cols-3 col-span-1 gap-0">
            <img class="w-full h-1/3 object-cover object-center  rounded-tr-2xl" src="{{linkedinMedias[1].url}}">
            <img class="w-full h-1/3 object-cover object-center" src="{{linkedinMedias[2].url}}">
            <img *ngIf="linkedinMedias.length==4" class="w-full h-1/3 object-cover object-center rounded-br-2xl" src="{{linkedinMedias[3].url}}">
            <div *ngIf="linkedinMedias.length>4" class="relative h-1/3">
                <img class="w-full h-full object-cover object-center rounded-br-2xl" src="{{linkedinMedias[3].url}}">
                <div class="absolute inset-0 bg-black bg-opacity-60 flex justify-center items-center rounded-br-2xl">
                    <span class="text-white text-2xl object-cover object-center ">+{{linkedinMedias.length-4}}</span>
                </div>
            </div>
        </div>
    </div>
</div>