export function sanitizeString(fileName:string): string {
  // Remove invalid characters, allow letters, numbers, dots, underscores, and dashes
  const sanitizedFileName = fileName
    .normalize('NFD') // Normalize the unicode characters
    .replace(/[\u0300-\u036f]/g, '') // Remove accents
    .replace(/[^a-zA-Z0-9._-]/g, '_') // Replace invalid characters with underscores
    .replace(/\s+/g, '_') // Replace spaces with underscores
    .replace(/\.+/g, '_') // Replace dots with underscores
    .trim(); // Trim leading/trailing whitespace

  return sanitizedFileName;
}