<!-- day posts preview -->
<div class="fixed overflow-y-auto shadow-lg border border-indigo-500 rounded-md bg-white" [style.max-height]="previewHeight"  [style.left.px]="xpos" [style.top.px]="ypos" *ngIf="isVisible" style="pointer-events: none;">
    <div class="post-preview relative bg-white px-4  mx-auto font-medium shadow-lg w-[25rem] max-h-[90vh] overflow-hidden" style="pointer-events: all;">
        <!-- Close button in the top right corner of the panel -->
        <button class="absolute top-0 right-0 mt-2 mr-3 text-sm hover:text-pink-600" (click)="close()">✕</button>
        
        <ul class="list-none mt-3">
            <li ><p class="text-xs text-gray-400">{{getDate(data[0])}}</p></li>
            <li class="flex justify-start items-center -mb-2" *ngFor="let post of data;let i=index">
                <span class="flex items-center text-3xl" [ngClass]="{
                    'c-accent-3': post.publishingDatetime != null,
                    'c-accent-4': post.expectedPublishingDatetime != null,
                    'mb-2':i==data.length-1 
                }">•</span>
                <span class="flex-1 text-sm justify-center px-2"
                [ngClass]="{
                    'mb-2':i==data.length-1 
                }">
                    {{ post.title?.length>30? post.title.substring(0, 30)+"...":post.title}}
                </span>
                <span class="text-sm time">
                    {{getTime(post)}}
                </span>
            </li>
        </ul>
    </div>
</div>
