<div class=" mx-auto  overflow-hidden" [ngClass]="{'w-11/12 pt-6': !dashboard}">
<!-- <div class="w-11/12 mx-auto pt-6 overflow-hidden" >  the original code --> 
	<div class="filters grid grid-rows-1 grid-cols-6 gap-6  -mt-6" [ngClass]="{'hidden': dashboard}">
	<!-- <div class="filters grid grid-rows-1 grid-cols-6 gap-6  -mt-6">  original code  -->
		<div class="col-span-1 h-full">
			<app-date-filter/>
		</div>
		<div class="col-span-3 h-full">
			<app-platform-filter [isSmallView]="isSmallView" [isMediumView]="isMediumView" [windowWidth]="windowWidth" [isLargeView]="isLargeView" [isVerySmallView]="isVerySmallView"/>
		</div>
		<div class="col-span-2 h-full xs:grid-cols-1 sm:grid-cols-1">
			<div class="py-2 px-3 bg-white border border-[2px] border-slate-500 rounded-xl h-full">
				<span class="flex justify-between text-center">
					<span class="font-semibold text-sm">{{'posts.type'|translate}} :</span> 

					<button 
						type="button" 
						[ngClass]="{
							'text-white c-b-gray': isDraft,
							'bg-gray-100 text-gray-400': !isDraft,
							'px-5 w-[75px]': !isVerySmallView, 
							'px-3 w-[70px]': isVerySmallView
						}"
						class="rounded-full text-sm flex justify-center items-center" (click)="toggleDraft()"
					>
						{{ 'generic.draft' | translate }}
					</button>

					<button 
						type="button" 
						[ngClass]="{
							'text-white bg-cliking_yellow': isPlanned,
							'bg-gray-100 text-gray-400': !isPlanned,
							'px-5 w-[75px]': !isVerySmallView, 
							'px-3 w-[70px]': isVerySmallView
						}"
						class="rounded-full text-sm flex justify-center items-center" 
						(click)="togglePlanned()"
					>
						{{ 'generic.planned' | translate }}
					</button>

					<button 
						(click)="togglePost()" 
						type="button" 
						[ngClass]="{
							'text-white c-b-accent-3': isPost, 
							'bg-gray-100 text-gray-400': !isPost, 
							'px-5 w-[75px]': !isVerySmallView, 
							'px-3 w-[70px]': isVerySmallView
						}" 
						class="rounded-full text-sm flex justify-center items-center" 
					>
						{{ 'generic.published' | translate }}
					</button>
					
				</span>
			</div>
		</div>
	</div>


	<div *ngIf="userHasPermission('publication.get.:id') && (!dashboardSource || dashboardSource == 'facebook' || dashboardSource == 'instagram')" class="flex justify-center gap-2 mt-5">
		<div 
			*ngIf="(!dashboardSource || dashboardSource == 'facebook')"
			class="p-2 bg-facebook text-white rounded-full  flex gap-1"
			(click)="fetchPosts('facebook')"
			[ngClass]="{
				'cursor-not-allowed': isFetchingForFacebookPosts,
				'cursor-pointer': !isFetchingForFacebookPosts
			}"
		>
			<div><span [ngClass]="{'cursor-not-allowed loading loading-spinner': isFetchingForFacebookPosts}"></span></div>
			{{"pages.fetchFacebookPosts" | translate}}
		</div>
		<div 
			*ngIf="(!dashboardSource || dashboardSource == 'instagram')"
			class="p-2 bg-pink-600 text-white rounded-full flex gap-1"	
			(click)="fetchPosts('instagram')"
			[ngClass]="{
				'cursor-not-allowed': isFetchingForInstagramPosts,
				'cursor-pointer': !isFetchingForInstagramPosts
			}"
		>
			<div><span [ngClass]="{'loading loading-spinner': isFetchingForInstagramPosts}"></span></div>
			{{"pages.fetchInstagramPosts" | translate}}
		</div>
	</div>
	<div class="overflow-auto h-[85vh] pb-20" (scroll)="infinitScroll($event)" [ngClass]="{'mt-10 ': !dashboard && !userHasPermission('publication.get.:id')}">
	<!-- <div class="mt-10 mb-5 overflow-auto h-[85vh] pb-20" (scroll)="infinitScroll($event)" > the original code -->
		<div class="columns-3 sm:columns-2 md:columns-3 lg:columns-4 xl:columns-4 2xl:columns-5 3xl:columns-6 m-5">
			<div *ngFor="let post of posts; index as i" class="masonry-item">
				<app-post-preview [user]="user" [index]="i" [displayAs]="'post'" [isPostFilter]="isPost" [isDraftFilter]="isDraft" [isPlannedFilter]="isPlanned" [postIndex]="i" [post]="post" (onDeleteClicked)="confirmDeleteModalVisible=true;currentDraft=post"></app-post-preview>
			</div>
		</div>
	</div>
</div>

<app-modal 
	[isOpen]="confirmDeleteModalVisible" 
	[icon]="modalIcon" 
	[Heading]="modalHeading"
	[subHeading]="modalSubHeading"
	(closeModal)="confirmDeleteModalVisible=false" 
	(deleteItem)="confirmDraftRemoval()"
></app-modal>

<app-loader [isLoading]="isLoading"/>
