import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  user: any;

  constructor(protected authService: AuthService, protected translationService:TranslationService ){}

  ngOnInit(): void {
    this.user = this.authService.loadUserfromLs()
    
  }

  externLinks(link: string){
    window.location.href = link
  }
  tr(key: string){
    return this.translationService.t(key)
  }

  getUserFirstName(): string {
    return this.authService.loadUserfromLs()?.firstName
  }
}
