<div class="h-full">
  <div
    *ngIf="posts.length > 0"
    class="border-2 border-indigo-400 bg-white rounded-tr-3xl rounded-3xl flex justify-center h-full"
  >
    <div class="w-[90%] text-sm my-4 pb-4">
      <div class="flex items-center text-center justify-between font-black">
        <span (click)="prevPost()" class="cursor-pointer">←</span>
        <span class="font-medium"
          >{{ posts[currentIndex]?.title }} ({{ currentIndex + 1 }}/{{
            posts.length
          }})</span
        >
        <span (click)="nextPost()" class="cursor-pointer">→</span>
      </div>

      <fieldset class="rounded-t-3xl h-full pb-5" >
        <!-- <legend class="px-2 text-center text-sm -m-2">
          {{ "posts.preview" | translate }}
        </legend> -->
					<!--  -->
					<!--  -->
					<!--  -->
				<ng-container>
					<app-social-media-tabs
						class="w-full h-full"	
						[origin]="'show-post'"
						[data]="preparePostDataForPreview()"
						[selectedPagesSources]="selectedPagesSources"
						[socialMediaPagesList]="socialMediaPagesList"
						[googleEventOfferStartDate]="googleEventOfferStartDate"
						[googleEventOfferEndDate]="googleEventOfferEndDate"
						[googleMediaBrowserContext]="googleMediaBrowserContext"
						[previewFilesList]="previewFilesList"
						[socialMediaPagesList]="socialMediaPagesList"
						[googleBtnValue]="googleBtnValue"
					></app-social-media-tabs>
				</ng-container>
					
					
        <!-- <img src="assets/img/no-post-image.png" class="w-3/4 m-auto border border-1 border-indigo-400 rounded" alt=""> -->
        <!-- <app-carousel [slides]="previewFilesLoad()" /> -->
				 
			  <!-- <app-post-plateform-preview [data]="post()" [screenHeight]="screenHeight" [copyPost]="false" [screenHeight]="screenHeight" [screenWidth]="screenWidth"  [selectedPlateform]="getPostSocialMedias()[currentPlatformIndex]?.source.toLowerCase()"/> -->

        <!-- socialmedia preview -->

        <!-- <div
          class="flex items-center justify-between font-black"
          *ngIf="getPostSocialMedias().length > 1"
        >
          <span (click)="prevSocialMedia()" class="cursor-pointer ml-20"
            >←      </span
          >
          <span
            class="px-2 m-2 rounded-full sm:w-28"
            [ngClass]="{
                    'bg-sky-600': getPostSocialMedias()[currentPlatformIndex]?.source.toLowerCase()==='facebook',
                    'bg-orange-600': getPostSocialMedias()[currentPlatformIndex]?.source.toLowerCase()==='google',
                    'bg-pink-600': getPostSocialMedias()[currentPlatformIndex]?.source.toLowerCase()==='instagram',
                    'bg-blue-600': getPostSocialMedias()[currentPlatformIndex]?.source.toLowerCase()==='linkedin',
                }"
          >
            <img
              class="m-auto"
              [src]="
                'assets/img/' +
                getPostSocialMedias()[
                  currentPlatformIndex
                ]?.source.toLowerCase() +
                '-label.png'
              "
              [style.max-height.px]="screenHeight*45/100"
              alt="social media platform"
            />
          </span>

          <span (click)="nextSocialMedia()" class="cursor-pointer mr-20"
            >     →</span
          >
        </div> -->
      </fieldset>

      <!-- <fieldset class="border border-1 text-center border-black rounded-3xl my-3">
        <legend class="px-2 text-sm mb-1">
          {{ "generic.date" | translate }}
        </legend>
        <div class="flex justify-center w-full">
          <span class="font-medium text-base mb-3">
            {{ getpostDateAndTimeStr() }}
          </span>
        </div>
      </fieldset> -->
    </div>
  </div>
  <div
    *ngIf="posts.length == 0"
    class="w-full h-[73vh] border border-2 border-indigo-400 bg-white rounded-3xl flex justify-center"
  >
    <span class="m-5 m-auto text-base text-gray-300">
      {{ "posts.no-data-for-date" | translate }} {{ day }}
    </span>
  </div>
  <!-- <button
    *ngIf="posts.length > 0"
    class="w-full c-b-accent-1 text-white rounded-bl-3xl rounded-br-3xl py-2 hover:bg-indigo-600 font-medium"
    type="button"
    (click)="SendPostForUpdate()"
  >
    {{ "posts.edit" | translate }}
  </button> -->

  <!-- <button
    *ngIf="posts.length > 0"
    class="w-full cursor-not-allowed  rounded-bl-3xl rounded-br-3xl py-2 font-medium"
    type="button"
    (click)="SendPostForUpdate()"
    [disabled]="true"
    title="Coming Soon..."
    [ngClass]="{'cursor-pointer bg-cliking_blue text-white': user?.isAdmin, 'text-gray-300 cursor-not-allowed disabled bg-gray-400': !user?.isAdmin}"
    [disabled]="!user?.isAdmin"
  >
    {{ "posts.copy" | translate }}
  </button> -->

  <!-- <button
    *ngIf="posts.length > 0"
    class="w-full cursor-not-allowed  rounded-bl-3xl rounded-br-3xl py-2 font-medium"
    type="button"
    (click)="SendPostForUpdate()"
    [disabled]="true"
    title="Coming Soon..."
    [ngClass]="{'cursor-pointer bg-cliking_blue text-white': user?.isAdmin, 'text-gray-300 cursor-not-allowed disabled bg-gray-400': !user?.isAdmin}"
    [disabled]="!user?.isAdmin"
  >
    {{ "posts.edit" | translate }}
  </button> -->
</div>
