import { Component, HostListener } from '@angular/core';
import { PostService } from 'src/app/services/repositories/post.service';
import { Subscription } from 'rxjs';
import { GroupService } from 'src/app/services/repositories/group.service';
import { IResponse } from 'src/app/interfaces/IResponse';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  data: any[] = [
    {"label": "dashboard.numberOfPublications", "value":0},
    {"label": "dashboard.numberOfViews", "value":0},// total impressions
    {"label": "dashboard.reactionsObtained", "value":0}, //total reactions
    {"label": "dashboard.commentsReceived", "value":0}, //total comment
    {"label": "dashboard.sharesMade", "value":0}, //total shares
    {"label": "dashboard.interactions", "value":0}, // total engagements
  ];
  private subs = new Subscription();
  socialMedias: any[] = [];
  linkedinData: any[] = [];
  googleData: any[] = [];
  facebookData: any[] = [];
  screenWidth: number = 0;
  screenHeight: number = 0;
  instagramData: any[] = [{"label": "insights.instagram.media_count", "value":'---'},
    {"label": "insights.instagram.impressions", "value":'---'},
    {"label": "insights.instagram.likes", "value":'---'},
    {"label": "insights.instagram.comments", "value":'---'},
    {"label": "insights.instagram.shares", "value":'---'},
    {"label": "insights.instagram.total_interactions", "value":'---'},
    {"label": "insights.instagram.follows", "value":'---'},
    {"label": "insights.instagram.profile_visits", "value":'---'},
    {"label": "insights.instagram.profile_activity", "value":'---'},
    {"label": "insights.instagram.saves", "value":'---'},
    {"label": "insights.instagram.replies", "value":'---'},
    {"label": "insights.instagram.profile_views", "value":'---'},
    {"label": "insights.instagram.website_clicks", "value":'---'},
    {"label": "insights.instagram.profile_links_taps", "value":'---'},
    {"label": "insights.instagram.followers_count", "value":'---'},
    {"label": "insights.instagram.accounts_engaged", "value":'---'},
  ];

  constructor(protected postService: PostService,
    private groupService: GroupService,private screenSizeService: ScreenSizeService){}

  ngOnInit(){
    this.screenSizeService.screenWidth$.subscribe((width: number) => this.screenWidth = width);
    this.screenSizeService.screenHeight$.subscribe((height: number) => this.screenHeight = height);
    this.subs.add(
      this.postService.currentGroups.subscribe((g: number[]) => {
        if (g.length == 0)
          g = this.postService.getGroupScopesFromLocalStorage();
        this.linkedinData = [{"label": "dashboard.numberOfPublications", "value":0},
          {"label": "insights.linkedin.impressionCount", "value":0},
          {"label": "insights.linkedin.likeCount", "value":0},
          {"label": "insights.linkedin.commentCount", "value":0},
          {"label": "insights.linkedin.shareCount", "value":0},
          {"label": "insights.linkedin.totalEngagement", "value":0},
          {"label": "insights.linkedin.engagementRate", "value":0},
          {"label": "insights.linkedin.clickRate", "value":0},];
        this.facebookData = [ {"label": "dashboard.numberOfPublications", "value":0},
          {"label": "insights.facebook.post_impressions", "value":0},
          {"label": "insights.facebook.reactions", "value":0},
          {"label": "insights.facebook.comments", "value":0},
          {"label": "insights.facebook.shares", "value":0},
          {"label": "insights.facebook.engagements", "value":0},
          {"label": "insights.facebook.reach", "value":0},
          {"label": "insights.facebook.page_follows", "value":0},
          {"label": "insights.facebook.page_fan_adds", "value":0},
          {"label": "insights.facebook.page_fan_removes", "value":0},];
        this.googleData =  [{"label": "dashboard.numberOfPublications", "value":0},
          {"label": "dashboard.CallClicks", "value":0},
          {"label": "dashboard.WebsiteClicks", "value":0},
          {"label": "dashboard.BusinessBookings", "value":0},
          {"label": "dashboard.BusinessFoodOrders", "value":0},
          {"label": "dashboard.BusinessConversations", "value":0},
          {"label": "dashboard.BusinessFoodMenuClicks", "value":0},
          {"label": "dashboard.BusinessDirectionRequests", "value":0},
          {"label": "dashboard.BusinessImpressionsMobileMaps", "value":0},
          {"label": "dashboard.BusinessImpressionsDesktopMaps", "value":0},
          {"label": "dashboard.BusinessImpressionsMobileSearch", "value":0},
          {"label": "dashboard.BusinessImpressionsDesktopSearch", "value":0}]
          this.instagramData = [{"label": "insights.instagram.media_count", "value":0},
            {"label": "insights.instagram.impressions", "value":0},
            {"label": "insights.instagram.likes", "value":0},
            {"label": "insights.instagram.comments", "value":0},
            {"label": "insights.instagram.shares", "value":0},
            {"label": "insights.instagram.total_interactions", "value":0},
            {"label": "insights.instagram.follows", "value":0},
            {"label": "insights.instagram.profile_visits", "value":0},
            {"label": "insights.instagram.profile_activity", "value":0},
            {"label": "insights.instagram.saves", "value":0},
            {"label": "insights.instagram.replies", "value":0},
            {"label": "insights.instagram.profile_views", "value":0},
            {"label": "insights.instagram.website_clicks", "value":0},
            {"label": "insights.instagram.profile_links_taps", "value":0},
            {"label": "insights.instagram.followers_count", "value":0},
            {"label": "insights.instagram.accounts_engaged", "value":0},
          ];
        if (g.length > 0) {
          this.groupService.getGroupByIds(g).subscribe((res: IResponse) => {
            this.getRecursiveSocialMediaPages(
              res.data
            );
            this.socialMedias.forEach((item: any)=>{
              if(item.source == 'linkedin' && item.analytics ){
                this.linkedinData[0].value += this.gVal(item.analytics?.numberOfPublications);
                this.linkedinData[1].value += this.gVal(item.analytics?.impressionCount);
                this.linkedinData[2].value += this.gVal(item.analytics?.likeCount);
                this.linkedinData[3].value += this.gVal(item.analytics?.commentCount);
                this.linkedinData[4].value += this.gVal(item.analytics?.shareCount);
                this.linkedinData[5].value += this.gVal(item.analytics?.totalEngagement);
                this.linkedinData[6].value += this.gVal(item.analytics?.engagementRate);
                this.linkedinData[7].value += this.gVal(item.analytics?.clickRate);
              }
              if(item.source == 'facebook' && item.analytics){
                item.analytics.forEach((element:any)=>{
                  // console.log("element", element);
                  if(element.name =='number_of_publications')
                    this.facebookData[0].value += this.gVal(element.value);
                  if(element.name =='page_posts_impressions')
                    this.facebookData[1].value += this.gVal(element.value);
                  if(element.name =='page_actions_post_reactions_total'){
                      this.facebookData[2].value += this.gVal(Object.values(element.value).reduce((accumulator: any, currentValue: any) => {
                        return accumulator + currentValue;
                      }, 0));
                    }
                    if(element.name =='total_comments_on_page')
                      this.facebookData[3].value += this.gVal(element.value);
                    if(element.name =='total_shares_on_page')
                      this.facebookData[4].value += this.gVal(element.value);
                    if(element.name =='page_total_actions')
                      this.facebookData[5].value += this.gVal(element.value);
                    if(element.name =='page_post_engagements')
                      this.facebookData[6].value += this.gVal(element.value);
                    if(element.name =='page_follows')
                      this.facebookData[7].value += this.gVal(element.value);
                    if(element.name =='page_fan_adds')
                      this.facebookData[8].value += this.gVal(element.value)
                    if(element.name =='page_fan_removes')
                      this.facebookData[9].value += this.gVal(element.value)
                  })
              }
              if(item.source == 'google' && item.analytics){
                this.googleData[0].value += this.gVal(item.analytics?.numberOfPublications);
                this.googleData[1].value += this.gVal(item.analytics?.WEBSITE_CLICKS);
                this.googleData[2].value += this.gVal(item.analytics?.BUSINESS_BOOKINGS);
                this.googleData[3].value += this.gVal(item.analytics?.BUSINESS_FOOD_ORDERS);
                this.googleData[4].value += this.gVal(item.analytics?.CALL_CLICKS);
                this.googleData[5].value += this.gVal(item.analytics?.BUSINESS_CONVERSATIONS);
                this.googleData[6].value += this.gVal(item.analytics?.BUSINESS_FOOD_MENU_CLICKS);
                this.googleData[7].value += this.gVal(item.analytics?.BUSINESS_DIRECTION_REQUESTS);
                this.googleData[8].value += this.gVal(item.analytics?.BUSINESS_IMPRESSIONS_MOBILE_MAPS);
                this.googleData[9].value += this.gVal(item.analytics?.BUSINESS_IMPRESSIONS_DESKTOP_MAPS);
                this.googleData[10].value += this.gVal(item.analytics?.BUSINESS_IMPRESSIONS_MOBILE_SEARCH);
                this.googleData[11].value += this.gVal(item.analytics?.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH);
              }
              if(item.source == 'instagram' && item.analytics ){
                for(let insight of item.analytics){
                  if(insight.name=="media_count"){
                    this.instagramData[0].value += this.gVal(insight.value)
                  }
                  if(insight.name=="impressions"){
                    this.instagramData[1].value += this.gVal(insight.value)
                  }
                  if(insight.name=="likes"){
                    this.instagramData[2].value += this.gVal(insight.value)
                  }
                  if(insight.name=="comments"){
                    this.instagramData[3].value += this.gVal(insight.value)
                  }
                  if(insight.name=="shares"){
                    this.instagramData[4].value += this.gVal(insight.value)
                  }
                  if(insight.name=="total_interactions"){
                    this.instagramData[5].value += this.gVal(insight.value)
                  }
                  if(insight.name=="follows"){
                    this.instagramData[6].value += this.gVal(insight.value)
                  }
                  if(insight.name=="profile_visits"){
                    this.instagramData[7].value += this.gVal(insight.value)
                  }
                  if(insight.name=="profile_activity"){
                    this.instagramData[8].value += this.gVal(insight.value)
                  }
                  if(insight.name=="saves"){
                    this.instagramData[9].value += this.gVal(insight.value)
                  }
                  if(insight.name=="replies"){
                    this.instagramData[10].value += this.gVal(insight.value)
                  }
                  if(insight.name=="profile_views"){
                    this.instagramData[11].value += this.gVal(insight.value)
                  }
                  if(insight.name=="website_clicks"){
                    this.instagramData[12].value += this.gVal(insight.value)
                  }
                  if(insight.name=="profile_links_taps"){
                    this.instagramData[13].value += this.gVal(insight.value)
                  }
                  if(insight.name=="followers_count"){
                    this.instagramData[14].value += this.gVal(insight.value)
                  }
                  if(insight.name=="accounts_engaged"){
                    this.instagramData[15].value += this.gVal(insight.value)
                  }

                }
              }
            })
            this.linkedinData[6].value = parseFloat((this.linkedinData[6].value).toFixed(2));
            this.linkedinData[7].value = parseFloat((this.linkedinData[7].value).toFixed(2));
            
            let longestLenghtData = Math.max(this.googleData.length, this.facebookData.length, this.linkedinData.length, this.instagramData.length);
            let shortestLenghtData = Math.min(this.googleData.length, this.facebookData.length, this.linkedinData.length, this.instagramData.length);
            this.data[0].value = this.facebookData[0].value + this.linkedinData[0].value + this.googleData[0].value + this.instagramData[0].value 
            this.data[1].value = this.facebookData[1].value + this.linkedinData[1].value + this.instagramData[0].value
            this.data[2].value = this.facebookData[2].value + this.linkedinData[2].value + this.instagramData[2].value
            this.data[3].value = this.facebookData[3].value + this.linkedinData[3].value + this.instagramData[3].value
            this.data[4].value = this.facebookData[4].value + this.linkedinData[4].value + this.instagramData[4].value
            this.data[5].value = this.facebookData[5].value + this.linkedinData[5].value + this.instagramData[5].value
            for(let i = 0; i< longestLenghtData - shortestLenghtData ; i++){
              if(this.googleData.length != longestLenghtData)
                this.googleData.push({"label": "", "value":''})
              if(this.facebookData.length != longestLenghtData)
                this.facebookData.push({"label": "", "value":''})
              if(this.linkedinData.length != longestLenghtData)
                this.linkedinData.push({"label": "", "value":''})
              if(this.instagramData.length != longestLenghtData)
                this.instagramData.push({"label": "", "value":''})
            }
          });
        }
      })
    );
  }

  getRecursiveSocialMediaPages(groups: any[]): any[] {
    groups = !Array.isArray(groups) ? [groups] : groups;
    let socialMediaPages: any[] = [];
    groups.forEach((group: any) => {
      // Add the group's social media pages if any
      if (group.socialMedia && group.socialMedia.length > 0) {
        socialMediaPages = [...socialMediaPages, ...group.socialMedia];
      }
      // Recursively add social media pages from children
      if (group.children && group.children.length > 0) {
        for (let child of group.children) {
          socialMediaPages = [
            ...socialMediaPages,
            ...this.getRecursiveSocialMediaPages(child),
          ];
        }
      }
    });
    //removing any duplicates
    const pagesArrayString: string[] = socialMediaPages.map((sm: any) =>
      JSON.stringify(sm)
    );
    const uniquePagesArrayString = [...new Set(pagesArrayString)];
    this.socialMedias = uniquePagesArrayString.map((sm: any) =>
      JSON.parse(sm)
    );
    return uniquePagesArrayString.map((sm: any) => JSON.parse(sm));
  }

  gVal(data: any){
    return data && Number(data)? Number(data): 0
  }

}

