import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { Response } from 'src/app/interfaces/Response';
import { UserService } from '../../../services/repositories/user.service';
import { GroupService } from '../../../services/repositories/group.service';
import { User } from 'src/app/interfaces/User';
import { Group } from 'src/app/interfaces/Group';
import Swal from 'sweetalert2';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  choosedItem: any;
  @Input() res: Response = {
    data: [],
    navigation: {
      page: 1,
      totalPages: 1,
      totalRows: 0,
      itemsPerPage: 10,
    },
    success: false,
    error: '',
    status: 0,
  };
  activeItemId!: any;
  isEditModeOn: boolean = false;
  isModalOpen: boolean = false;
  modalIcon: ICON = ICON.delete;
  head: string = '';
  subHead: string = '';

  @Input() skipColumns: string[] = [];
  @Input() skipEdit: boolean = false;
  @Input() readOnlyKeys: string[] = [];
  @Input() isWriteEnabled: boolean = false;
  @Input() entity: string = '';
  @Input() isBrowsable: boolean = false;
  @Input() isAdmin: boolean | null = null;

  @Output() deleteItem = new EventEmitter<any>();
  @Output() editItem = new EventEmitter<any>();
  @Output() UpdateUsers = new EventEmitter<void>();
  @Output() UpdateGroups = new EventEmitter<void>();
  @Output() openChangePasswordModal = new EventEmitter<any>();
  @Output() openfetchPostsModal = new EventEmitter<any>();

  activePath: string[] = [];

  constructor(private translationService: TranslationService) {}

  getDynamicDataHeaders(): string[] {
    let result: string[] = [];
    if (this.res?.data && this.res?.data.length > 0) {
      result = Object.keys(this.res?.data[0]).filter((column: string) => {
        return !this.skipColumns.includes(column);
      });
    }
		//console.log("dynamical headers:", result)
    return result;
  }

  scope: any;
  ngOnInit() {
    this.scope = this.res?.data;
  }

  tableEditClicked(item: any) {
    this.isEditModeOn = !this.isEditModeOn;
    this.activeItemId = this.isEditModeOn ? item.id : null;
    if (!this.isEditModeOn) {
      this.head = 'generic.modificationSuccessful';
      this.subHead = '';
      this.modalIcon = ICON.success;
      this.isModalOpen = true;
      this.editItem.emit({
        item: item,
        entity: this.entity,
        confirmed: !this.isEditModeOn,
      });
      setTimeout(() => {
        this.close();
      }, 1500);
    }
  }

  tableDeleteClicked(item: any) {
    this.activeItemId = this.isEditModeOn ? item.id : null;
    this.deleteItem.emit({
      item: item,
      entity: this.entity,
      confirmed: !this.isEditModeOn,
    });
    this.close();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['res']) {
      // Debug.log("res just got updated!!")
      if (this.res?.data) {
        this.scope = this.res.data;
      }
    }
  }

  //navigate to nested groups (for groups navigation logic)
  navigateTo(data: any) {
    if (this.isBrowsable) {
      //update
      const subgroups = this.scope.filter(
        (item: any) => item.name == data.name
      )[0].children;
      //set subgroupCount property
      subgroups.map((item: any) => {
        item.Subgroups = item.children.length;
      });
      if (subgroups.length > 0) {
        this.scope = subgroups;
        this.activePath = data.ancestry;
        // Debug.log("navigating to ",data,"currentScope ",this.scope)
      }
    }
  }

  setScopTo(groupName: string) {
    //get the group from res.data

    // Debug.log("finding ",groupName," in ",this.res.data)
    const newScope = this.findGroupByName(groupName).children;

    // Debug.log("reverting to scope ",newScope)
    this.scope = newScope;
  }

  findGroupByName(name: string) {
    // Start the recursive search from the root nodes
    return this.recursiveSearch(name, this.res.data);
  }

  recursiveSearch(name: string, nodes: any) {
    for (const node of nodes) {
      // Check if the current node's name matches the target name
      if (node.name === name) {
        return node;
      }
      // If the node has children, continue searching among the children
      if (node.children) {
        const found: any = this.recursiveSearch(name, node.children);
        if (found) {
          return found;
        }
      }
    }
    return undefined; // Return undefined if no matching node is found
  }

  //navigators event
  @Output() firstClicked: EventEmitter<any> = new EventEmitter();
  @Output() lastClicked: EventEmitter<any> = new EventEmitter();
  @Output() nextClicked: EventEmitter<any> = new EventEmitter();
  @Output() previoustClicked: EventEmitter<any> = new EventEmitter();
  first() {
    this.firstClicked.emit(this.res.navigation);
  }

  last() {
    this.lastClicked.emit(this.res.navigation);
  }
  previous() {
    this.previoustClicked.emit(this.res.navigation);
  }
  next() {
    this.nextClicked.emit(this.res.navigation);
  }

  openChangePassword(item: any) {
    Debug.log('openChangePassword', item);
    this.openChangePasswordModal.emit(item);
  }
  openfetchPosts(item: any) {
    Debug.log('openfetchPosts', item);
    this.openfetchPostsModal.emit(item);
  }

  //close modal
  close() {
    this.isModalOpen = false;
  }

  // delete item
  delete() {
    this.tableDeleteClicked(this.choosedItem);
  }

  openModal(item: any) {
    this.subHead = 'generic.confirmUserDeletionMsg';
    this.head = 'generic.confirmDeletion';
    this.modalIcon = ICON.delete;
    this.isModalOpen = true;
    this.choosedItem = item;
  }
}
