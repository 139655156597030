import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';

import { PostService } from 'src/app/services/repositories/post.service';
import { SOCIAL_MEDIA_PLATFORMS } from 'src/app/utils/social-media.constant';
import { BaseComponent } from '../../base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';
// Import Fuse.js if using for fuzzy search

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent extends BaseComponent {
  @Input() isMultiSelect: boolean = false;
  @Input() useCheckbox: boolean = false;
  @Input() enableFuzzySearch: boolean = false;
  @Input() customCss: any;
  @Input() sizeCss: any;
  toolTipMsgPages: boolean = false;
  @Output() booleanEmitter = new EventEmitter<boolean>();
  @Input() data: Array<{
    id: number;
    name: string;
    groupId: string;
    group: any;
    source: string;
    children?: any[];
  }> = [];
  @Input() precheckedPages: { [key: number]: boolean } | null = {};
  @Input() caption: string = 'no caption provided';
  @Input() bgColorClass: string = 'bg-white';
  @Input() tailwindCss: string = '';
  @Output() toggleChanged: EventEmitter<any> = new EventEmitter<any>();
  SOCIAL_MEDIA_PLATFORMS = SOCIAL_MEDIA_PLATFORMS;

  searchPattern: string = '';
  // State for open/close nested items
  public toggledItems: { [key: number]: boolean } = {};
  initialCaption: string = this.getTranslation('generic.choosePage');
  initialData: Array<{
    id: number;
    name: string;
    groupId: string;
    group: any;
    source: string;
    children?: any[];
  }> = JSON.parse(JSON.stringify(this.data));
  previousData: Array<{
    id: number;
    name: string;
    groupId: string;
    group: any;
    source: string;
    children?: any[];
  }> = [];
  screenWidth: number = 0;
  screenHeight: number = 0;
  constructor(
    translationService: TranslationService,
    private postService: PostService,
    private eRef: ElementRef,
    private screenSizeService: ScreenSizeService
  ) {
    super(translationService);
  }

  public selectedSources: any[] = [];
  ngOnInit(): void {
    //reset any previous toggled items
    // this.toggledItems = {};
    // this.selectedSources = [];
    // this.toggledItems = {};
    this.caption = this.getTranslation('socialMedia.choosePage');
    // this.postService.currentGroups.subscribe((_g: any) => {
    //   // this.postService.updateToggledItems(this.toggledItems);
    //   // this.postService.updateSelectedSources(this.selectedSources);
    // });

    if (this.postService.selectedSourcesSubject.getValue().length > 0) {
      this.toggledItems = this.postService.selectedSourcesSubject
        .getValue()
        .reduce((acc, sm) => {
          acc[sm.id] = true;
          return acc;
        }, {});

      this.postService.updateToggledItems(this.toggledItems);
    }
    this.postService.currentToggledItems.subscribe((toggledItems) => {
      this.toggledItems = toggledItems;
    });

    this.postService.currentSelectedPages.subscribe((selectedSources) => {
      this.selectedSources = selectedSources;
    });
    this.screenSizeService.screenWidth$.subscribe(
      (width: number) => (this.screenWidth = width)
    );
    this.screenSizeService.screenHeight$.subscribe(
      (height: number) => (this.screenHeight = height)
    );
    this.previousData = this.data;
    this.updateCaption();
  }

  ngOnChanges(changes: SimpleChanges): void {
		// console.log("changes", changes)
    // if (changes['data'] && this.data.length != 0) {
		// 	if (changes['data'].currentValue && changes['data'].previousValue){
		// 		const diff1 = this.data.filter((x: any) => !this.previousData.some((y: any) => y.id === x.id));
		// 		console.log("diff1", diff1)
		// 		// diff1.forEach((item:any) => this.toggleSelect(item))
		// 	}
		// 	this.updateCaption();
    //   this.initialData = JSON.parse(JSON.stringify(this.data));
    // }

    if (changes['precheckedPages'] && this.precheckedPages != null) {
      // Debug.log("pages auto selected",Object.keys(this.precheckedPages))

      this.postService.updateToggledItems(this.precheckedPages as any);
      if (this.postService.selectedSourcesSubject.getValue().length == 0) {
        for (let page of this.data) {
          if (Object.keys(this.precheckedPages).includes(page.id.toString())) {
            this.selectedSources.push(page);
          }
        }
      } else {
        this.selectedSources =
          this.postService.selectedSourcesSubject.getValue();
      }
      this.postService.updateSelectedSources(this.selectedSources);
    }
    this.updateCaption();
    this.previousData = this.data;
    if (this.data.length == 1) {
      console.log('this.data END CHANGE', this.data);
      console.log('this.toggledItems END CHANGE', this.toggledItems);
      this.toggleSelect(this.data[0], true);
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  isDropdownOpen = false;
  public isAllSelected = false;
  toggleDropdown(): void {
    if (this.data.length > 0) this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleSelect(item: any, state: boolean | null = null): void {
    this.toggledItems[item.id] = state ? state : !this.toggledItems[item.id];

    if (this.toggledItems[item.id] && !this.selectedSources.includes(item)) {
      this.selectedSources.push(item);
    } else if (
      !this.toggledItems[item.id] &&
      this.selectedSources.includes(item)
    ) {
      this.selectedSources = this.selectedSources.filter(
        (sourceItem) => sourceItem.id !== item.id
      );
    }
    //check if all selected
    this.isAllChecked();
    this.postService.updateToggledItems(this.toggledItems);
    this.postService.updateSelectedSources(this.selectedSources);
    this.updateCaption();
    this.toolTipMsgPages = this.selectedSources.length === 0;
    this.booleanEmitter.emit(this.toolTipMsgPages);
  }

  toggleSelectAll(data: any): void {
    //this will eith select all or unselect all
    const toggleValue = !this.isAllChecked();

    //check if all selected
    for (let item of data) {
      this.toggledItems[item.id] = toggleValue;
    }
    this.selectedSources = toggleValue ? data : [];
    this.isAllSelected = toggleValue;

    this.postService.updateToggledItems(this.toggledItems);
    this.postService.updateSelectedSources(this.selectedSources);

    this.updateCaption();
  }

  isAllChecked() {
    this.isAllSelected =
      Object.keys(this.toggledItems).length == this.data.length &&
      this.data.length > 0 &&
      Object.values(this.toggledItems).every((value) => value === true);
    this.postService.updateToggledItems(this.toggledItems);

    return this.isAllSelected;
  }

  updateCaption() {
    //check if none selected
    this.toggledItems = this.postService.toggledItemsSource.getValue();

    if (
      Object.values(this.toggledItems).every((value) => value === false) ||
      Object.values(this.toggledItems).length == 0
    ) {
      this.caption = this.getTranslation('socialMedia.choosePage');
      return;
    }
    const toggledItemsArray: any = [];

    for (const [id, isSelected] of Object.entries(this.toggledItems)) {
      toggledItemsArray.push({ id, isSelected });
    }
    const selectedpagesCount = toggledItemsArray.filter(
      (toggledItem: { id: number; isSelected: boolean }) =>
        toggledItem.isSelected
    ).length;

    if (selectedpagesCount == 1)
      this.caption =
        selectedpagesCount.toString() +
        ' ' +
        this.getTranslation('generic.selectedPage');
    else
      this.caption =
        selectedpagesCount.toString() +
        ' ' +
        this.getTranslation('generic.selectedPages');
  }

  searchItems(searchTerm: string): void {
    if (searchTerm === '') {
      this.data = JSON.parse(JSON.stringify(this.initialData));
    } else {
      const regex = new RegExp(searchTerm, 'i');
      this.data = this.initialData.filter((d) => regex.test(d.name));
    }
  }

  getPlatformProperty(
    source: string,
    property: keyof (typeof this.SOCIAL_MEDIA_PLATFORMS)[0]
  ): any {
    const platform = this.SOCIAL_MEDIA_PLATFORMS.find(
      (p) => p.source === source
    );
    return platform ? platform[property] : null;
  }

	selectAllPagesFromPlatform(diff: any[]){
		console.log("selectAllPagesFromPlatform in app-dropdown")
		diff.forEach((item:any) => this.toggleSelect(item))
	}
}
