import { Component, HostListener } from '@angular/core';
import { PostService } from 'src/app/services/repositories/post.service';
import { Subscription } from 'rxjs';
import { GroupService } from 'src/app/services/repositories/group.service';
import { IResponse } from 'src/app/interfaces/IResponse';

@Component({
  selector: 'app-facebook-stats',
  templateUrl: './facebook-stats.component.html',
  styleUrls: ['./facebook-stats.component.scss']
})
export class FacebookStatsComponent {
  data: Array<{
    label: string;
    value: string;
    percentage: number;
  }> = [];
  private subs = new Subscription();
  socialMedias: any[] = [];
  constructor(protected postService: PostService,
    private groupService: GroupService,
    ){}

  ngOnInit(){
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    
    this.data = [
      {"label": "insights.facebook.number_of_publications", "value":'0', "percentage":7},
      {"label": "insights.facebook.page_posts_impressions", "value":'0', "percentage":3},
      {"label": "insights.facebook.page_actions_post_reactions_total", "value":'0', "percentage":2},
      {"label": "insights.facebook.total_comments_on_page", "value":'0', "percentage":-4},
      {"label": "insights.facebook.total_shares_on_page", "value":'0', "percentage":5},
      {"label": "insights.facebook.page_post_engagements", "value":'0', "percentage":5},
      {"label": "insights.facebook.page_follows", "value":'0', "percentage":5},
      {"label": "insights.facebook.page_fan_adds", "value":'0', "percentage":5},
      {"label": "insights.facebook.page_fan_removes", "value":'0', "percentage":5},
    ];
    this.subs.add(
      this.postService.currentGroups.subscribe((g: number[]) => {
        if (g.length == 0)
          g = this.postService.getGroupScopesFromLocalStorage();
        
        if (g.length > 0) {
          this.groupService.getGroupByIds(g).subscribe((res: IResponse) => {
            this.getRecursiveSocialMediaPages(
              res.data
            );
            let page_actions_post_reactions_total = 0; 
            let commentCount = 0;
            let total_shares_on_page = 0;
            let clickCount = 0;
            let page_post_engagements = 0;
            let page_posts_impressions = 0;
            let number_of_publications = 0;
            let page_follows = 0
            let page_fan_adds = 0;
            let page_fan_removes = 0;
            this.socialMedias.forEach((item: any)=>{
              if(item.source == 'facebook' && item.analytics){
                item.analytics.forEach((element:any)=>{
                  console.log("element",element)
                    if(element.name =='number_of_publications')
                      number_of_publications += this.gVal(element.value);
                    if(element.name =='page_actions_post_reactions_total'){
                      page_actions_post_reactions_total = this.gVal(Object.values(element.value).reduce((accumulator: any, currentValue: any) => {
                        return accumulator + currentValue;
                      }, 0));
                    }
                    if(element.name =='page_posts_impressions')
                      page_posts_impressions += this.gVal(element.value);
                    if(element.name =='page_post_engagements')
                      page_post_engagements += this.gVal(element.value);
                    if(element.name =='page_total_actions')
                      clickCount += this.gVal(element.value);
                    if(element.name =='page_fan_adds')
                      page_fan_adds += this.gVal(element.value)
                    if(element.name =='total_comments_on_page')
                      commentCount += this.gVal(element.value);
                    if(element.name =='page_fan_removes')
                      page_fan_removes += this.gVal(element.value)
                    if(element.name =='total_shares_on_page')
                      total_shares_on_page += this.gVal(element.value);
                    if(element.name =='page_follows')
                      page_follows += this.gVal(element.value);
                })
              }
              
            })
            this.data[0].value = number_of_publications.toString();
            this.data[1].value = page_posts_impressions.toString();
            this.data[2].value = page_actions_post_reactions_total.toString();
            this.data[3].value = commentCount.toString();
            this.data[4].value = total_shares_on_page.toString();
            this.data[5].value = page_post_engagements.toString();
            this.data[6].value = page_follows.toString();
            this.data[7].value = page_fan_adds.toString();
            this.data[8].value = page_fan_removes.toString();
          });
        }
      })
    );

    console.log(this.data)
  }

  getRecursiveSocialMediaPages(groups: any[]): any[] {
    groups = !Array.isArray(groups) ? [groups] : groups;
    let socialMediaPages: any[] = [];
    groups.forEach((group: any) => {
      // Add the group's social media pages if any
      if (group.socialMedia && group.socialMedia.length > 0) {
        socialMediaPages = [...socialMediaPages, ...group.socialMedia];
      }
      // Recursively add social media pages from children
      if (group.children && group.children.length > 0) {
        for (let child of group.children) {
          socialMediaPages = [
            ...socialMediaPages,
            ...this.getRecursiveSocialMediaPages(child),
          ];
        }
      }
    });
    //removing any duplicates
    const pagesArrayString: string[] = socialMediaPages.map((sm: any) =>
      JSON.stringify(sm)
    );
    const uniquePagesArrayString = [...new Set(pagesArrayString)];
    this.socialMedias = uniquePagesArrayString.map((sm: any) =>
      JSON.parse(sm)
    );
    return uniquePagesArrayString.map((sm: any) => JSON.parse(sm));
  }

  gVal(data: any){
    return data && Number(data)? Number(data): 0
  }

  // delete this part 
  isPlanned: boolean = true; 
  isPost: boolean = true; 
  isDraft: boolean = true; 
  windowWidth: number = window.innerWidth;
  isLargeView: boolean = false;
  isMediumView: boolean = false;
  isSmallView: boolean = false;
  isVerySmallView: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;

    if (1232 < this.windowWidth && this.windowWidth < 1375) {
      // Medium view
      this.isLargeView = false;
      this.isMediumView = true;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (1375 <= this.windowWidth && this.windowWidth < 1515) {
      // Large view
      this.isLargeView = true;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (this.windowWidth >= 1515) {
      // Extra large view
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (this.windowWidth >= 1100 && this.windowWidth < 1232) {
      // Small view (windowWidth <= 1232)
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = true;
      this.isVerySmallView = false;
    } else {
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = true;
    }
  }

  togglePost() {
    this.isPost = !this.isPost;
  }
  togglePlanned() {
    this.isPlanned = !this.isPlanned;
  }
  // end delete

}
