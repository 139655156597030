import { Component, Output, EventEmitter, Input } from '@angular/core';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';

@Component({
  selector: 'app-platform-filter',
  templateUrl: './platform-filter.component.html',
  styleUrls: ['./platform-filter.component.scss'],
})
export class PlatformFilterComponent {
  constructor(private uiStateService: UiStateService) {}

  @Input() currentPlatforms: any = {
    isFacebookON: true,
    isLinkedinON: true,
    isGoogleON: true,
    isInstagramON: true,
  };
  @Input() windowWidth: any;
  @Input() isLargeView: any;
  @Input() isMediumView: any;
  @Input() isSmallView: any;
  @Input() isVerySmallView: any;

  ngOnInit() {
    this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      // Debug.log(data)
      this.currentPlatforms = data;
    });
    console.log("-------------------",this.currentPlatforms);
    
  }
  
  toggleGoogle(): void {
    this.togglePlatform('isGoogleON');
    console.log("-------------------",this.currentPlatforms);
  }
  
  toggleFacebook(): void {
    this.togglePlatform('isFacebookON');
    console.log("-------------------",this.currentPlatforms);
  }
  
  toggleLinkedIn(): void {
    this.togglePlatform('isLinkedinON');
    console.log("-------------------",this.currentPlatforms);
  }
  
  toggleInstagram(): void {
    this.togglePlatform('isInstagramON');
    console.log("-------------------",this.currentPlatforms);
  }

  private togglePlatform(platformKey: string): void {
    this.currentPlatforms[platformKey] = !this.currentPlatforms[platformKey];
    this.uiStateService.updatePlatformsFilter(this.currentPlatforms);
    this.uiStateService.platformsFilterUpdated.next(this.currentPlatforms);
  }
}
