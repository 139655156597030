import { Component, HostListener} from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-social-media-connect',
  templateUrl: './social-media-connect.component.html',
  styleUrls: ['./social-media-connect.component.scss']
})


export class SocialMediaConnectComponent {
	apiUrl:string = environment.apiUrl;
  windowWidth: number = window.innerWidth;
  isMobileView: boolean = false;
	showFacebookModal: boolean = false;

  constructor() {
    this.checkWindowSize(); 
  }
	
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;
    if(this.windowWidth < 1150)
      this.isMobileView = true;
    else
      this.isMobileView = false;
  }

  private checkWindowSize() {
    this.windowWidth = window.innerWidth;
  }
	

	googleConnect(){
    //start passport authentication to google as promis
    // in the then method set googleConnect to true
		//show save google account information to current group
		window.location.href = `${this.apiUrl}/social-auth/google`;
  }

  linkedInConnect(){
		window.location.href = `${this.apiUrl}/social-auth/linkedin`;
  }

	// Open the modal
  openFacebookModal(): void {
    this.showFacebookModal = true;
  }

  // Close the modal
  closeFacebookModal(): void {
    this.showFacebookModal = false;
  }

	 // Facebook connect function with parameter
	 facebookConnect(option: string): void {
    this.closeFacebookModal(); // Close the modal after action
		window.location.href = `${this.apiUrl}/social-auth/facebook?configType=${option}`;
  }
	
  instagramConnect(){
		window.location.href = `${this.apiUrl}/social-auth/instagram`;
  }

}
