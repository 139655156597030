<div id="post-label" class="post-item text-xs pb-1  mx-1 h-16 m-auto " *ngIf="posts?.length>0 && isPostPlatformInFilter(posts)" [attr.data-post-id]="1"> 
	<div [attr.data-post-id]="posts[previewIndex].id" class="post-item rounded-md h-full w-full m-auto grid" 
		[ngClass]="{
			'grid-cols-10': !hasManyLabels(),
			'grid-cols-11': hasManyLabels()
		}"
	>
		<!-- c1: Post Title and Text -->
		<div class="col-span-5 p-1 post-item rounded-tl-md rounded-bl-md" [attr.data-post-id]="posts[previewIndex].id" 
			[ngClass]="{
				' gap-6 c-b-accent-3 text-white': posts[previewIndex].publishingDatetime != null,
				' gap-6 c-b-accent-4 text-black': posts[previewIndex].publishingDatetime == null,
			}"
		> 
			<h3 class="post-item" [attr.data-post-id]="posts[previewIndex].id" >{{posts[previewIndex].title.length > 20 ? posts[previewIndex].title.slice(0, 20) + '...' : posts[previewIndex].title}}</h3> 
			<h3 class="post-item" [attr.data-post-id]="posts[previewIndex].id"  >{{posts[previewIndex].text.length > 25 ? posts[previewIndex].text.slice(0, 25) + '...' : posts[previewIndex].text}}</h3> 
		</div>

		<div class="col-span-5 overflow-y-auto post-item rounded-tr-md rounded-br-md" [attr.data-post-id]="posts[previewIndex].id"
			[ngClass]="{
				' gap-6 c-b-accent-3 text-white': posts[previewIndex].publishingDatetime != null,
				' gap-6 c-b-accent-4 text-black': posts[previewIndex].publishingDatetime == null,
			}" 
		> 
			<span *ngFor="let platform of getUniquePlateforms(posts[previewIndex].SocialPlatforms)" 
				class="post-item inline-flex items-center mt-1 justify-center rounded-full w-24" 
				[attr.data-post-id]="posts[previewIndex].id" 
				[ngClass]="{
					'bg-sky-600': platform.toLowerCase() == 'facebook' && currentPlaTformsFilters.isFacebookON,
					'bg-orange-600': platform.toLowerCase() == 'google' && currentPlaTformsFilters.isGoogleON,
					'bg-blue-600': platform.toLowerCase() == 'linkedin' && currentPlaTformsFilters.isLinkedinON,
					'bg-pink-600': platform.toLowerCase() == 'instagram'&& currentPlaTformsFilters.isInstagramON
				}"
			>
				<span *ngIf="platform.toLowerCase() == 'facebook' && currentPlaTformsFilters.isFacebookON || platform.toLowerCase() == 'google' && currentPlaTformsFilters.isGoogleON || platform.toLowerCase() == 'linkedin' && currentPlaTformsFilters.isLinkedinON || platform.toLowerCase() == 'instagram' && currentPlaTformsFilters.isInstagramON">
				<img src="assets/img/{{ platform.toLowerCase() }}-label.png" alt="{{ platform.source }}" class="post-item w-full h-auto" [attr.data-post-id]="posts[previewIndex].id" > 
				</span>
			</span>
		</div>
		<!-- c3: Additional Posts Indicator (Shown only if hasManyLabels is true) -->
		<span class="col-span-1 my-1 w-full mx-1  bg-none " *ngIf="hasManyLabels()">
			<span (click)="showFullHourLabelPannel(posts)" class="pannel-trigger cursor-pointer h-full flex justify-center items-center bg-white border-2 border-indigo-400 rounded text-xs text-indigo-400 m-auto ">
				<p class="pannel-trigger">+{{posts.length - 1}}<br>&nbsp;→</p>
			</span>
		</span>
	</div>
</div>
