import { Component, HostListener } from '@angular/core';
import { PostService } from 'src/app/services/repositories/post.service';
import { Subscription } from 'rxjs';
import { GroupService } from 'src/app/services/repositories/group.service';
import { IResponse } from 'src/app/interfaces/IResponse';



@Component({
  selector: 'app-linkedin-stats',
  templateUrl: './linkedin-stats.component.html',
  styleUrls: ['./linkedin-stats.component.scss']
})
export class LinkedinStatsComponent {
  data: Array<{
    label: string;
    value: string;
    percentage: number;
  }> = [];
  private subs = new Subscription();
  socialMedias: any[] = [];

  constructor(protected postService: PostService,
  private groupService: GroupService,
  ){}

  ngOnInit(){ 
    this.data = [
      {"label": "dashboard.numberOfPublications", "value":'0', "percentage":7},
      {"label": "insights.linkedin.impressionCount", "value":'0', "percentage":3},
      {"label": "insights.linkedin.likeCount", "value":'0', "percentage":2},
      {"label": "insights.linkedin.commentCount", "value":'0', "percentage":-4},
      {"label": "insights.linkedin.shareCount", "value":'0', "percentage":5},
      {"label": "insights.linkedin.totalEngagement", "value":'0', "percentage":5},
      {"label": "insights.linkedin.engagementRate", "value":'0', "percentage":5},
      {"label": "insights.linkedin.clickCount", "value":'0', "percentage":5},
      {"label": "insights.linkedin.clickRate", "value":'0', "percentage":5},
    ];
    this.subs.add(
      this.postService.currentGroups.subscribe((g: number[]) => {
        if (g.length == 0)
          g = this.postService.getGroupScopesFromLocalStorage();
        
        if (g.length > 0) {
          this.groupService.getGroupByIds(g).subscribe((res: IResponse) => {
            this.getRecursiveSocialMediaPages(
              res.data
            );
            let likeCount = 0; 
            let commentCount = 0;
            let shareCount = 0;
            let clickCount = 0;
            let engagementRate = 0;
            let clickRate=0 
            let totalEngagement=0
            let impressionCount = 0;
            let numberOfPublications = 0;
            this.socialMedias.forEach((item: any)=>{
              if(item.source == 'linkedin'){
                numberOfPublications += this.gVal(item.analytics?.numberOfPublications);
                impressionCount += this.gVal(item.analytics?.impressionCount);
                likeCount += this.gVal(item.analytics?.likeCount);
                commentCount += this.gVal(item.analytics?.commentCount);
                shareCount += this.gVal(item.analytics?.shareCount);
                clickCount += this.gVal(item.analytics?.clickCount);
                totalEngagement += this.gVal(item.analytics?.totalEngagement);
                clickRate+=this.gVal(item.analytics?.clickRate);
              }
            })
            this.data[0].value = numberOfPublications.toString();
            this.data[1].value = impressionCount.toString();
            this.data[2].value = likeCount.toString();
            this.data[3].value = commentCount.toString();
            this.data[4].value = shareCount.toString();
            this.data[5].value = totalEngagement.toString();
            this.data[6].value = ((engagementRate/this.socialMedias.length) || 0).toFixed(2).toString()+'%';
            this.data[7].value = clickCount.toString();
            this.data[8].value = ((clickRate/this.socialMedias.length) || 0).toFixed(0).toString();
          });
        }
      })
    );
  }
  getRecursiveSocialMediaPages(groups: any[]): any[] {
    groups = !Array.isArray(groups) ? [groups] : groups;
    let socialMediaPages: any[] = [];
    groups.forEach((group: any) => {
      // Add the group's social media pages if any
      if (group.socialMedia && group.socialMedia.length > 0) {
        socialMediaPages = [...socialMediaPages, ...group.socialMedia];
      }

      // Recursively add social media pages from children
      if (group.children && group.children.length > 0) {
        for (let child of group.children) {
          socialMediaPages = [
            ...socialMediaPages,
            ...this.getRecursiveSocialMediaPages(child),
          ];
        }
      }
    });
    //removing any duplicates
    const pagesArrayString: string[] = socialMediaPages.map((sm: any) =>
      JSON.stringify(sm)
    );
    const uniquePagesArrayString = [...new Set(pagesArrayString)];
    this.socialMedias = uniquePagesArrayString.map((sm: any) =>
      JSON.parse(sm)
    );
    return uniquePagesArrayString.map((sm: any) => JSON.parse(sm));
  }

  gVal(data: any){
    return data && Number(data)? Number(data): 0
  }
  // delete this part 
  isPlanned: boolean = true; 
  isPost: boolean = true; 
  isDraft: boolean = true; 
  windowWidth: number = window.innerWidth;
  isLargeView: boolean = false;
  isMediumView: boolean = false;
  isSmallView: boolean = false;
  isVerySmallView: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;

    if (1232 < this.windowWidth && this.windowWidth < 1375) {
      // Medium view
      this.isLargeView = false;
      this.isMediumView = true;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (1375 <= this.windowWidth && this.windowWidth < 1515) {
      // Large view
      this.isLargeView = true;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (this.windowWidth >= 1515) {
      // Extra large view
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (this.windowWidth >= 1100 && this.windowWidth < 1232) {
      // Small view (windowWidth <= 1232)
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = true;
      this.isVerySmallView = false;
    } else {
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = true;
    }
  }

  togglePost() {
    this.isPost = !this.isPost;
  }
  togglePlanned() {
    this.isPlanned = !this.isPlanned;
  }
  // end delete
}