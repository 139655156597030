import {  Component,ElementRef,EventEmitter,Input,Output,SimpleChanges,ViewChild,} from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { Debug } from 'src/app/utils/debug';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/utilities/auth.service';

@Component({
  selector: 'app-post-preview',
  templateUrl: './post-preview.component.html',
  styleUrls: ['./post-preview.component.scss'],
})
export class PostPreviewComponent extends BaseComponent {
  constructor(
    translationService: TranslationService,
    protected postService: PostService,
    protected uiStateService: UiStateService,
    protected authService: AuthService
  ) {
    super(translationService);
  }
  @Input() post: any;
  @Input() postIndex!: number;
  @Input() displayAs!: string;
  @Input() isPostFilter!: boolean;
  @Input() isDraftFilter!: boolean;
  @Input() isPlannedFilter!: boolean;
  @Input() index!: number;
  apiUrl = environment.apiUrl;
  @Input() user: any;

  ngAfterViewInit() {
    this.attachImageLoadListeners();
    this.observeContentChanges();
    this.adjustOverlayHeight();
  }

  private mutationObserver!: MutationObserver;
  mainMedia: any = null;
  ngOnChange(change:SimpleChanges){
    if(change['post']){
      console.log("post updated");
      
    }
  }
  ngOnInit(): void {
    
    // console.log(JSON.stringify(this.post.SocialMediaPlatforms))
    this.SetDisplayAs();
    if (this.post.title == undefined || this.post.title == '') {
      this.post.title = 'N/A';
    }

    this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      // Debug.log(data)
      this.currentPlaTformsFilters = data;
      //when platform filter si selected with chek if a preview platforms are all hidden so we can hide it 
      const platforms=this.post.SocialPlatforms.map((sm:any)=> sm.source)
      const platformFilterMapping:any = {
        facebook: this.currentPlaTformsFilters.isFacebookON,
        google: this.currentPlaTformsFilters.isGoogleON,
        linkedin: this.currentPlaTformsFilters.isLinkedinON,
        instagram: this.currentPlaTformsFilters.isInstagramON,
      };
    
      // Check if all platforms in the list are off
      const allOff = platforms.every((platform:string) => !platformFilterMapping[platform]);
      this._canShowPreview = !allOff 
     // console.log("allOff",allOff,platforms);


      this.isPostPlatformSelected();
    });
    if (this.displayAs == 'planned') {
      console.log(this.post);
    }

    this.mutationObserver = new MutationObserver(() => {
      this.adjustOverlayHeight();
    });

    this.setMainMedia();
    this.postAnalytics();
	}

  SetDisplayAs() {
    if (
      this.post.publishingDatetime == null &&
      this.post.expectedPublishingDatetime == null
    ) {
      this.displayAs = 'draft';
    } else if (new Date(this.post.expectedPublishingDatetime) > new Date()) {
      this.displayAs = 'planned';
    } else {
      this.displayAs = 'post';
    }
  }

  @Output() onDeleteClicked: EventEmitter<any> = new EventEmitter();
  emitOpenModal() {
    this.onDeleteClicked.emit();
  }

  @ViewChild('overlay') overlayElement!: ElementRef;
  @ViewChild('content') contentElement!: ElementRef;
  private adjustOverlayHeight(): void {
    const contentHeight = this.contentElement.nativeElement.offsetHeight;
    this.overlayElement.nativeElement.style.height = `${contentHeight}px`;
  }

  private attachImageLoadListeners(): void {
    const images: NodeListOf<HTMLImageElement> =
      this.contentElement.nativeElement.querySelectorAll('img');
    images.forEach((img) => {
      if (!img.complete) {
        img.onload = () => this.adjustOverlayHeight();
      }
    });
  }

  private observeContentChanges(): void {
    this.mutationObserver.observe(this.contentElement.nativeElement, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    });
  }

  currentPlaTformsFilters: any = {
    isFacebookON: true,
    isLinkedinON: true,
    isGoogleON: true,
    isInstagramON: true,
  };

  // isPublished: boolean = true;
   isPlanned: boolean = true;
  // isDraft: boolean = true;

  getPostGroupsNamesList() {
    let groupListStr: string = '';
    for (let socialMedia of this.post.SocialPlatforms) {
      if (!groupListStr.includes(socialMedia.group.name)) {
        groupListStr += socialMedia.group.name + ' ' + ',';
      }
    }

    return groupListStr.substring(0, groupListStr.length - 1).trim();
  }

  setMainMedia(): void {
    if (this.post.SocialPlatforms?.length > 0) {
      for (const socialMedia of this.post.SocialPlatforms) {
        if (socialMedia.medias?.length > 0) {
          this.mainMedia = socialMedia.medias[0];
          break;
        }
      }
    }
  }

  mediaIsVideo(media: any): boolean {
    return media?.mimeType?.startsWith('video') ?? false;
  }

  formatPostStats(num: number) {
    if (Math.abs(num) < 1000) {
      return num.toString();
    } else if (Math.abs(num) >= 1000 && Math.abs(num) < 1000000) {
      return (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1) + 'k';
    } else {
      return (Math.sign(num) * (Math.abs(num) / 1000000)).toFixed(1) + 'm';
    }
  }


  filterValue(){
    return this.isPostFilter && this.displayAs === 'post' ||  this.isDraftFilter && this.displayAs === 'draft'|| this.isPlannedFilter && this.displayAs === 'planned'
  }

  useAsNewPostTemplate() {
    const postJsonStr = JSON.stringify(this.post);
    const mediaQuery = `/posts?useAs=${this.displayAs}&postTemplate=${encodeURIComponent(postJsonStr)}`;

    Debug.log(mediaQuery);
    window.location.href = mediaQuery;
  }

  calculatePastTimeFromNow() {
    let postTime = '';
    let futurePost = false;
    if (this.displayAs === 'post' || this.displayAs === 'planned') {
      postTime =
        this.post.publishingDatetime == null
          ? this.post.expectedPublishingDatetime
          : this.post.publishingDatetime;
    }
    if (this.displayAs === 'draft') {
      postTime = this.post.createdAt;
    }
    const postTimeUtc = new Date(postTime);

    const now = new Date(); // Current time

    let delta = Math.abs(now.getTime() - postTimeUtc.getTime()) / 1000; // Delta in seconds

    if (postTimeUtc > now) {
      // Post is in the future
      futurePost = true;
    }

    // Calculate differences and round up if necessary
    let unit = '';
    let value = 0;

    if (delta >= 3600 * 24 * 365) {
      value = Math.ceil(delta / (3600 * 24 * 365));
      unit = 'year';
    } else if (delta >= 3600 * 24 * 30) {
      value = Math.ceil(delta / (3600 * 24 * 30));
      unit = 'month';
    } else if (delta >= 3600 * 24) {
      value = Math.ceil(delta / (3600 * 24));
      unit = 'day';
    } else if (delta >= 3600) {
      value = Math.ceil(delta / 3600);
      unit = 'hour';
    } else if (delta >= 60) {
      value = Math.ceil(delta / 60);
      unit = 'minute';
    } else {
      value = Math.ceil(delta);
      unit = 'second';
    }

    // Format output
    let output = `${this.getTranslation(
      futurePost ? 'generic.willBePostedIn' : 'generic.hasbeen'
    )} ${value} ${this.getTranslation(
      `generic.short-${unit}${value > 1 ? 's' : ''}`
    )} `;
    if (this.displayAs == 'post')
      output =
        // this.getTranslation('generic.published') +
        // ' ' +
        output + ' ' + this.getTranslation('generic.on');
    if (this.displayAs == 'draft')
      output = this.getTranslation('generic.createdDraft') + ' ' + output;
    return output.trim();
  }

  private _canShowPreview: boolean = true;

  // Use a setter for the canShowPreview property
  set canShowPreview(value: boolean) {

    if(this.displayAs == 'draft' && this.isPlanned) 
    this._canShowPreview = value;
    if (this.contentElement) {
      const contentHeight = this.contentElement.nativeElement.offsetHeight;

      const overlayHeight = this.overlayElement.nativeElement.offsetHeight;
      // if (!value)
      //   Debug.log(
      //     '_canShowPreview is now false for post',
      //     this.postIndex,
      //     'contentHeight',
      //     contentHeight,
      //     'overLayHeight',
      //     overlayHeight
      //   );
      // else
      //   Debug.log(
      //     '_canShowPreview is now true for post',
      //     this.postIndex,
      //     'contentHeight',
      //     contentHeight,
      //     'overLayHeight',
      //     overlayHeight
      //   );
    }
    // Check if the view is initialized to avoid errors
    if (value && this.contentElement) {
      // Adjust the overlay height when the value changes to true
      setTimeout(() => this.adjustOverlayHeight(), 300);
    }
  }

  // Use a getter to maintain accessibility to the canShowPreview value
  get canShowPreview(): boolean {
    return this._canShowPreview;
  }

  isPostPlatformSelected(): boolean {
    let showfacebook =
      this.post.SocialPlatforms.filter((sm: any) => {
        sm.source == 'facebook' && this.currentPlaTformsFilters.isFacebookON;
      }).length > 0;
    let showlinkedin =
      this.post.SocialPlatforms.filter((sm: any) => {
        sm.source == 'linkedin' && this.currentPlaTformsFilters.isLinkedinON;
      }).length > 0;
    let showgoogle =
      this.post.SocialPlatforms.filter((sm: any) => {
        sm.source == 'google' && this.currentPlaTformsFilters.isGoogleON;
      }).length > 0;
    let showInstagram =
      this.post.SocialPlatforms.filter((sm: any) => {
        sm.source == 'instagram' && this.currentPlaTformsFilters.isInstagramON;
      }).length > 0;
    this.canShowPreview = this.areAllPostPlatformsFiltred();

    return showfacebook || showgoogle || showlinkedin || showInstagram;
  }

  areAllPostPlatformsFiltred(): boolean {
    let allSelected: boolean[] = [];
    let currentPostPlatforms = this.post.SocialPlatforms;
    currentPostPlatforms.forEach((platform: any) => {
      switch (platform.source) {
        case 'facebook':
          allSelected.push(this.currentPlaTformsFilters.isFacebookON);
          break;
        case 'google':
          allSelected.push(this.currentPlaTformsFilters.isGoogleON);
          break;
        case 'linkedin':
          allSelected.push(this.currentPlaTformsFilters.isLinkedinON);
          break;
        case 'instagram':
          allSelected.push(this.currentPlaTformsFilters.isInstagramON);
          break;
      }
    });

    const allFalse = allSelected.every((value) => value === false);
    return !allFalse;
  }

  ngOnDestroy(): void {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  postDetails() {
  //  const postJsonStr = JSON.stringify(this.post);
    const postId = this.post.id

    const mediaQuery = `/library/post-details/${postId}`;
		window.location.href = mediaQuery;


  }

  postAnalytics() {
    this.post.views = 0;
    this.post.likeCount = 0;
    this.post.comments = 0;
    this.post.shares = 0;
    this.post.SocialPlatforms.forEach((item: any) => {
      if (item.source == 'linkedin') {
        this.post.likeCount += this.gVal(item?.postAnalytics?.likeCount);
        this.post.views += this.gVal(item?.postAnalytics?.clickCount);
        this.post.comments += this.gVal(item?.postAnalytics?.commentCount);
        this.post.shares += this.gVal(item?.postAnalytics?.shareCount);
      }
      if (item.source == 'facebook') {
        this.post.likeCount += this.gVal(item?.postAnalytics?.reactions);
        this.post.views += this.gVal(item?.postAnalytics?.metrics[0].value);
        this.post.comments += this.gVal(item?.postAnalytics?.comments);
        this.post.shares += this.gVal(item?.postAnalytics?.shares);
      }
      if (item.source == 'instagram') {
        this.post.likeCount += this.gVal(item?.postAnalytics?.find((insight:any)=>insight.name == "likes"));
        this.post.views += this.gVal(item?.postAnalytics?.find((insight:any)=>insight.name == "impressions"));
        this.post.comments += this.gVal(item?.postAnalytics?.find((insight:any)=>insight.name == "comments"));
        this.post.shares += this.gVal(item?.postAnalytics?.find((insight:any)=>insight.name == "shares"));
      }
    });
  }

  gVal(data: any) {
    return data && Number(data) ? Number(data) : 0;
  }

	getUniquePlateforms(data: any[]) {
		return Array.from(new Set(data.map((item) => item.source)));
	}

  renderInstaVal(name:string){
    // const insight=this.instagramData?.postAnalytics?.find((insight:any)=>insight.name == name)
    //  return insight?insight.value:'0'
   }
	viewsA = [85, 51, 70, 38, 47, 55, 93, 33, 97, 41, 35, 64, 88, 49, 50, 30, 94, 63, 92, 100]
	likesA = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10]
	commentsA = [5, 3, 3, 5, 4, 5, 4, 4, 6, 6, 7, 3, 4, 6, 3, 6, 6, 6, 3, 4]
	sharesA = [3, 5, 2, 4, 3, 5, 2, 2, 4, 4, 4, 5, 5, 4, 5, 3, 3, 3, 4, 5]
	
	generateRandomInt(min:number, max:number){
		if (max >= min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } else {
      console.error('Max value must be greater than or equal to Min value');
			return 0
    }
	}

	userHasPermission(permission: string): boolean {
		return (
			this.user?.isAdmin || 
			this.user?.accreditations[0]?.profile?.permissions.some((profilePermission: any) => profilePermission.action == permission)
		)
	}
}
