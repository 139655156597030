import { Component, Input } from '@angular/core';
import { StatsIcon } from 'src/app/enums/statsSVGIcon';

@Component({
  selector: 'app-post-stats-widgets',
  templateUrl: './post-stats-widgets.component.html',
  styleUrls: ['./post-stats-widgets.component.scss']
})
export class PostStatsWidgetsComponent {
  @Input() value: string = "0"
  @Input() widgetLabel: string = ""
  @Input() icon:StatsIcon=StatsIcon.CLICK
  @Input() plateform: string = ''
  @Input() screenHeight: number = 0;
  @Input() screenWidth: number = 0;

  ngOnInit(){
    
  }

  isIconClick(){
    return this.icon === StatsIcon.CLICK 
  }
  isIconClickRate(){
    return this.icon === StatsIcon.CLICKRATE
  }
  isIconShare(){
    return this.icon === StatsIcon.SHARE 
  }
  isIconSmilyFace(){
    return this.icon === StatsIcon.SMILY_FACE 
  }
  isIconChatBubble(){
    return this.icon === StatsIcon.CHAT_BUBBLE 
  }
  isIconView(){
    return this.icon === StatsIcon.VIEW 
  }
  isIconImpression(){
    return this.icon === StatsIcon.IMPRESSION 
  }
  isIconUserFevorite(){
    return  this.icon === StatsIcon.USER_FAVORITE
  }
  isIconActivity(){
    return this.icon === StatsIcon.ACTIVITY
  }
  isIconHeart(){
    return this.icon === StatsIcon.HEART
  }
  isIconBookMark(){
    return this.icon === StatsIcon.BOOKMARK
  }
  isIconStar(){
    return this.icon === StatsIcon.STAR
  }
  isReaction(){
    return this.icon === StatsIcon.REACTIONS
  }
  isFbReaction(){
    return this.icon === StatsIcon.FBREACTIONS
  }
  isEngagement(){
    return this.icon === StatsIcon.ENGAGEMENT
  }
  isEngagementRate(){
    return this.icon === StatsIcon.ENGAGEMENTRATE
  }
  isSaved(){
    return this.icon === StatsIcon.SAVED
  }
  isInteraction(){
    return this.icon === StatsIcon.INTERACTION
  }
  isActivityProfil(){
    return this.icon === StatsIcon.ACTIVITYPROFIL
  }
  isNewFans(){
    return this.icon === StatsIcon.NEWFANS
  }
  isProfilVisit(){
    return this.icon === StatsIcon.PROFILVISIT
  }
  
}
