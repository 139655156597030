<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="hidden lg:flex lg:flex-1 lg:justify-end">
    <a (click)="switchFr()" class="flex items-center text-sm font-semibold leading-6 text-gray-900 mr-4">
        <img style="height: 18px;" src="{{env.apiUrl}}/assets/fr.png" alt="" class="flex-shrink-0 h-6 w-6 rounded-md"> 
        <span class="ml-1 cursor-pointer">fr</span>
    </a>
    <a (click)="switchEn()" class="flex items-center text-sm font-semibold leading-6 text-gray-900">
        <img style="height: 18px;" src="{{env.apiUrl}}/assets/en.png" alt="" class="flex-shrink-0 h-6 w-6 rounded-md"> 
        <span class="ml-1 cursor-pointer">en</span>
    </a>

  </div>
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img class="mx-auto h-20 w-auto" src="assets/img/logo.png" alt="Clicking social media">
      <h2 class="mt-10 text-center text-2xl font-bold lea
      ding-9 tracking-tight text-gray-900">{{'generic.signInToYourAccount' | translate}}</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form *ngIf="!recoveryPassword" class="space-y-6" >
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-gray-900">{{'generic.emailAddress'| translate}}</label>
          <div class="mt-2">
            <input id="email" name="email" type="email" autocomplete="email" #username required class=" pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">{{'generic.password'  | translate}}</label>

          </div>
          <div class="mt-1">
            <input id="password" name="password" type="password" autocomplete="current-password" #password required class=" pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
        <a (click)="recovery()" class="text-xs text-center cursor-pointer">{{'generic.forgotPassword'|translate}}</a>
        <div>
          <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" (click)="login(username.value, password.value, $event)">{{'generic.signIn' | translate}}</button>
        </div>
      </form>
      <form *ngIf="recoveryPassword" class="space-y-6" >
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-gray-900">{{'generic.emailResetPasswordLink'|translate}}</label>
          <div class="mt-2">
            <input id="email" name="email" type="email" autocomplete="email" #email required class=" pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div *ngIf="emailError" class="text-red-600 text-sm">{{ emailError | translate }}</div>
            <div *ngIf="emailValid" class="text-green-600 text-sm">{{ emailValid | translate }}</div>
          </div>
        </div>
        <a (click)="recovery()" class="text-xs text-center cursor-pointer">{{'generic.loginPage' | translate}}</a>
        <div>
          <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" (click)="resetPassword(email.value, $event)">{{'generic.sendRequest' | translate}}</button>
        </div>
      </form>

      <!-- <p class="mt-10 text-center text-sm text-gray-500">
        Not a member?
        <a href="#" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Signup now</a>
      </p> -->
    </div>
  </div>
