<div 
  (click)="toggleDropdown()" 
  style="position: relative; display: flex; justify-content: space-between; align-items: center;"
  class="bg-white text-sm font-semibold rounded-xl  p-2 w-full cursor-pointer"
  [ngClass]="{
    'border border-[2px] border-slate-500 ':!isDropdownOpen
  }">
    <span>
      {{ selected ? getTranslation(selected) : getTranslation(data[0]) }}
    </span>
    <svg class="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 512">
      <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
    </svg>
    <ul class="dropdown-menu absolute z-20 left-0 bg-white rounded-xl border border-[2px]  border-slate-500 shadow-lg w-full" [class.hidden]="!isDropdownOpen" style="top: calc(0%);">
      <li *ngFor="let item of data" (click)="onItemSelect(item,$event)" class="cursor-pointer text-sm font-semibold select-none relative py-1 pl-3 rounded-xl hover:bg-gray-100">
        <span [ngClass]="{'text-black': selected === item, 'text-gray-400': selected !== item}">{{getTranslation(item)}}</span>
      </li>
    </ul>
</div>
