import { Component, HostListener, Input } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { SocialMediaAPIService } from 'src/app/services/utilities/socialMediaApi.service';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { Debug } from 'src/app/utils/debug';
import { AuthService } from 'src/app/services/utilities/auth.service';

@Component({
  selector: 'app-posts-library',
  templateUrl: './posts-library.component.html',
  styleUrls: ['./posts-library.component.scss'],
})
export class PostsLibraryComponent extends BaseComponent {
  @Input() dashboard: boolean = false;
  @Input() dashboardSource: string | null = null;
  windowWidth: number = window.innerWidth;
  isLargeView: boolean = false;
  isMediumView: boolean = false;
  isSmallView: boolean = false;
  isVerySmallView: boolean = false;
  currentDateTime: string;

  constructor(
    translationService: TranslationService,
    protected postService: PostService,
    protected uiStateService: UiStateService,
		private socialMediaApiService: SocialMediaAPIService,
    protected authService: AuthService
  ) {
    super(translationService);
    this.currentDateTime = new Date().toString();
    this.checkWindowSize();
  }
  posts: any = [];
  plannedPosts: any = [];

  @Input() currentPlaTformsFilters: any = { // delete Input
    isFacebookON: true,
    isLinkedinON: true,
    isGoogleON: true,
    isInstagramON: true,
  };

  isLoading: boolean = false;
  doneLoadingPosts: boolean = false;
  previousScrollPosition = 0;

  isPublished: boolean = true;
  @Input() isPlanned: boolean = true; // delete Input
  @Input() isPost: boolean = true; // delete Input
  @Input() isDraft: boolean = true; // delete Input

  dateRange!: { startDate: Date; endDate: Date };
  groupScopes: number[] = [];

  user!: any;
  navigation: any = {
    itemsPerPage: 20,
    page: 0,
  };
  ngOnInit(): void {
    this.user = this.authService.loadUserfromLs();

    this.groupSubscription = this.postService.currentGroups.subscribe(
      (groups) => {
        this.navigation.offset = 0;
        this.posts = [];
        this.groupScopes = groups;
        this.SetActivePlatforms();
        this.getPosts();
      }
    );
    
    this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      this.currentPlaTformsFilters = data;
    });

    this.uiStateService.dateRangeUpdate$.subscribe((data) => {
      this.navigation.offset = 0;
      this.posts = [];
      this.dateRange = data;
      this.SetActivePlatforms();
      this.getPosts();
    });
    // get this month posts
    this.onResize();
  }

  activePlatforms: string[] = [];
  SetActivePlatforms(): void {
    this.activePlatforms = [];
    for (let platform in this.uiStateService.platformsFilter) {
      if (this.uiStateService.platformsFilter[platform]) {
        // console.log(platform.substring(2,platform.length-2).toLowerCase());
        this.activePlatforms.push(
          platform.substring(2, platform.length - 2).toLowerCase()
        );
      }
    }
  }

  infinitScroll(event: any) {
    const element = event.target;
    const isScrollingDown = element.scrollTop > this.previousScrollPosition;
    const isBottomReached =
      element.scrollHeight - element.scrollTop <= element.clientHeight + 1;
  
    if (isScrollingDown && isBottomReached) {
      if (this.doneLoadingPosts && !this.isLoading) {
        this.isLoading = true;
        this.getPosts(); // Load more posts
        console.log("You've reached the end of this chunk");
      }
    }
  
    // Update the previous scroll position
    this.previousScrollPosition = element.scrollTop;
  }

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   const currentScrollPosition = window.scrollY;
  //   // Check if the user is scrolling down
  //   const isScrollingDown = currentScrollPosition > this.previousScrollPosition;
  //   // Check if the user has scrolled to the bottom
  //   if (
  //     isScrollingDown &&
  //     window.innerHeight + window.scrollY >= document.body.offsetHeight - 1
  //   ) {
  //     if (this.doneLoadingPosts && !this.isLoading) {
  //       this.isLoading = true;
  //       this.getPosts();
  //       console.log("You've reached the end of this chunk");
  //     }
  //   }
  // }
  getPosts(): void {
    this.doneLoadingPosts = false;
    this.isLoading = true;
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;
    this.activePlatforms=[]
    for (let filter in this.currentPlaTformsFilters) {
      
      if (this.currentPlaTformsFilters[filter]) {
        console.log("filter =>",this.currentPlaTformsFilters[filter]);

        this.activePlatforms.push(filter.slice(2, -2).toLocaleLowerCase());
      }
    }
    this.activePlatforms = [...new Set(this.activePlatforms)];

    if (this.groupScopes.length > 0) {
      if (this.dateRange) {
        console.log(
          'getting posts between ',
          this.dateRange.startDate,
          'and',
          this.dateRange.endDate
        );

        // console.log('this.activePlatforms', this.activePlatforms);
        // console.log('this.currentPlaTformsFilters', this.currentPlaTformsFilters);
        this.postService
          .getByDateRange(
            this.dateRange.startDate,
            this.dateRange.endDate,
            this.groupScopes,
            this.navigation.limit,
            this.navigation.offset,
            this.activePlatforms
          )
          .subscribe((res) => {
            this.posts = [...this.posts, ...res.data];

            //make sure no duplicates
						console.log("this.posts in getposts", this.posts)
            Debug.log('backend navigation', res.navigation);
            //this.posts = [new Set(postsStr)].map((p:any) =>JSON.parse(p))

            this.navigation = res.navigation;
            this.navigation.limit = Number(this.navigation.limit);
            this.navigation.offset = Number(this.navigation.offset);
            if (res.data.length > 0) {
              this.navigation.offset += this.navigation.limit;
            }
            console.log('new navigation', this.navigation);

            Debug.log(
              'posts between',
              this.dateRange.startDate,
              'and',
              this.dateRange.endDate,
              this.posts
            );
            this.doneLoadingPosts = true;
            this.chekcloading();
            // this.postService.mergePostsBySocialPlatforms(this.posts);
          });
      }
    }
  }
  doneLoadingDrafts = false;
  chekcloading() {
    if (this.doneLoadingPosts) {
      this.isLoading = false;
    } else {
      this.isLoading = true;
    }
  }

  //modal data
  modalIcon = ICON.delete;
  modalHeading = 'generic.confirmDeletion';
  modalSubHeading = 'generic.confirmPostDeletionMsg';

  currentDraft: any = null;
  confirmDraftRemoval() {
		console.log("confirmDraftRemoval")
    Debug.log('draft deletion confirmed', this.currentDraft);

		// remove draft from post list
		const index = this.posts.findIndex((post:any) => post.id === this.currentDraft.id);
		if (index !== -1) {
			this.posts.splice(index, 1);
		}

		// delete draft
    this.deleteDraft(this.currentDraft);
		
  }

  confirmDeleteModalVisible: boolean = false;
  deleteDraft(draft: any) {
    Debug.log(`deleting draft `, draft);
    this.postService.deleteDraft(draft).subscribe((res) => {
      Debug.log(res);
      if (res.success) {
        //change modal icon and heading and hide them after 1.5 s
        this.modalIcon = ICON.success;
        this.modalHeading = 'generic.deleted';
        this.modalSubHeading = '';
        setTimeout(() => {
          this.confirmDeleteModalVisible = false;
          //  reset them back for the next draft deletion"
          this.modalIcon = ICON.delete;
          this.modalHeading = 'generic.confirmDeletion';
          this.modalSubHeading = 'generic.confirmPostDeletionMsg';
        }, 1500);
      }
    });
  }

  togglePost() {
    this.isPost = !this.isPost;
  }
  toggleDraft() {
    this.isDraft = !this.isDraft;
  }
  togglePlanned() {
    this.isPlanned = !this.isPlanned;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;

    if (1232 < this.windowWidth && this.windowWidth < 1375) {
      // Medium view
      this.isLargeView = false;
      this.isMediumView = true;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (1375 <= this.windowWidth && this.windowWidth < 1515) {
      // Large view
      this.isLargeView = true;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (this.windowWidth >= 1515) {
      // Extra large view
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (this.windowWidth >= 1100 && this.windowWidth < 1232) {
      // Small view (windowWidth <= 1232)
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = true;
      this.isVerySmallView = false;
    } else {
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = true;
    }
  }

	sortPosts(posts: any[]): any[] {
		posts.sort((a: any, b: any) => {
			const dateA = new Date(a.publishingDatetime || a.expectedPublishingDatetime || a.createdAt).getTime();
			const dateB = new Date(b.publishingDatetime || b.expectedPublishingDatetime || b.createdAt).getTime();
			
			return dateB - dateA;
		});
		
		return posts;
	}

  private checkWindowSize() {
    this.windowWidth = window.innerWidth;
  }

	userHasPermission(permission: string): boolean {
		return (
			this.user?.isAdmin || 
			this.user?.accreditations[0]?.profile?.permissions.some((profilePermission: any) => profilePermission.action == permission)
		)
	}

	isFetchingForFacebookPosts: boolean = false;
	isFetchingForInstagramPosts: boolean = false;
	fetchPosts(source: string) {
    switch (source) {
      // case 'linkedin':
      //   this.isFetchingForLinkedInPosts = true;
      //   this.linkedInFetchData.show = false;
      //   break;
			// case 'google':
			//   this.isFetchingForGooglePosts = true;
			//   break;
      case 'facebook':
        this.isFetchingForFacebookPosts = true;
        break;
      case 'instagram':
        this.isFetchingForInstagramPosts = true;
        break;
    }

		console.log("ICLthis.groupScopes", this.groupScopes)
		console.log("ICLthis.user", this.user)
    const pagesIds = this.user.accreditations[0].group.socialMedia
      .filter(
        (sm: any) => sm.source.toLowerCase() == source.toLocaleLowerCase()
      )
      .map((socialMedia: any) => socialMedia.id);

			console.log("pagesIds", pagesIds)
    this.socialMediaApiService.fetchPagePosts(pagesIds).subscribe((res) => {
      const syncData = res.data.data[0].fetchingResults;

      switch (source) {
        // case 'linkedin':
        //   this.linkedInFetchData.show = true;
        //   this.linkedinPostsCount =
        //     syncData.updatedPosts + syncData.insertedPost;
        //   this.linkedInFetchData.fetched =
        //     syncData.updatedPosts + syncData.insertedPosts;
        //   this.linkedInFetchData.added = syncData.insertedPosts;
        //   this.linkedInFetchData.updated = syncData.updatedPosts;
        //   this.isFetchingForLinkedInPosts = false;
        //   break;
				// case 'google':
				//   this.googleFetchData.show = true;
				//   this.googleFetchData.fetched =
				//     syncData.updatedPosts + syncData.insertedPosts;
				//   this.googleFetchData.added = syncData.insertedPosts;
				//   this.googleFetchData.updated = syncData.updatedPosts;
				//   this.isFetchingForGooglePosts = false;
				//   break;

        case 'facebook':
          // this.facebookFetchData.show = true;
          // this.facebookFetchData.fetched =
          //   syncData.updatedPosts + syncData.insertedPosts;
          // this.facebookFetchData.added = syncData.insertedPosts;
          // this.facebookFetchData.updated = syncData.updatedPosts;
          this.isFetchingForFacebookPosts = false;
					console.log('res.data.data FB', res.data.data)
					window.location.reload();
          break;
        case 'instagram':
          // this.instagramFetchData.show = true;
          // this.instagramFetchData.fetched =
          //   syncData.updatedPosts + syncData.insertedPosts;
          // this.instagramFetchData.added = syncData.insertedPosts;
          // this.instagramFetchData.updated = syncData.updatedPosts;
          this.isFetchingForInstagramPosts = false;
					console.log('res.data.data insta', res.data.data)
					window.location.reload();
          break;
      }
    });
  }

	
}
